export const COMPETITION_STATUS_ID = {
  PENDING: 1,
  STARTED: 2,
  FINISHED: 3,
  CONFIRMED: 4,
  CANCELLED: 5,
  REVIEW: 7,
};

export const COMPETITION_STATUS = {
  PENDING: 'pending',
  STARTED: 'started',
  FINISHED: 'finished',
  CONFIRMED: 'confirmed',
  CANCELLED: 'cancelled',
  REVIEW: 'review',
};

export const COMPETITION_TYPE_IDS = {
  TOURNAMENT: 2,
  WAGER: 4,
  RANKED: 5,
  MULTIPLAYER: 6,
};

export const COMPETITION_MODE_IDS = {
  HOUR: 'Hourly',
  DAILY: 'Daily',
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly'
};

export const MIN_COST_INSCRIPTION = 0.5;

export const DEFAULT_CHAT_CHECK_IN_MINUTES = 30;

export const COMPETITION_PROPOSAL_STATUS_ID = {
  PENDING: 1,
  ACCEPTED: 2,
  REJECTED: 3,
  CANCELLED: 4,
};

export const getPlatformIcon = (platformImage: string): string => {
  return platformImage.replace('.svg', '');
};


export const EVENTS = {
  RELOAD_ARENA_PAGE: 'RELOAD_ARENA_PAGE',
  RELOAD_MINIGAME_PAGE: 'RELOAD_MINIGAME_PAGE',
  RELOAD_WALLET_PAGE: 'RELOAD_WALLET_PAGE',
  RELOAD_COMPETITIONS_PAGE: 'RELOAD_COMPETITIONS_PAGE',
  RELOAD_COMPETITION_PAGE: 'RELOAD_COMPETITION_PAGE',
  RELOAD_RANKED_PAGE: 'RELOAD_RANKED_PAGE',
  ON_PLAY_GAME_MODAL_CLOSE: 'ON_PLAY_GAME_MODAL_CLOSE',
  SOCIAL_LOGIN: 'SOCIAL_LOGIN',
  DEPOSIT_REMINDER_MODAL_CLOSE: 'DEPOSIT_REMINDER_MODAL_CLOSE',
};

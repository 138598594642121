
  import Vue, {PropType} from "vue";
  import PwaSlider from "@/components/common/PwaSlider.vue";
  import PwaPrice from "@/components/common/PwaPrice.vue";

  export default Vue.extend({
    name: "pwa-prizes-list-slider",
    components: {
      PwaSlider,
      PwaPrice
    },
    props: {
      prizesList: {
        type: Array as PropType<{id: number|string, avatar: string, username: string, quantity:number, transaction_date: string}[]>,
        required: true
      },
      numRows: {
        type: Number,
        required: false,
        default: 2,
      },
    },
    computed: {
      prizesListOrganized(): any[] {
        const list = [];
        const totalPrizesDistributed = this.prizesList.length;
        if (totalPrizesDistributed > 0) {
          const prizesByRow = Math.floor(totalPrizesDistributed / this.numRows);
          let lastEnd = 0;
          for (let i = 1; i <= this.numRows; i++) {
            const nextEnd = lastEnd + prizesByRow;
            const end = i < this.numRows
              ? nextEnd
              : totalPrizesDistributed;
            list.push(this.prizesList.slice(lastEnd, end));
            lastEnd = end;
          }
        }
        return list;
      },
    }
  });

import { getFirebaseDb, getFirebaseStorage } from "@/services/firebase";
import {
  ref,
  query,
  limitToLast,
  orderByKey,
  orderByChild,
  endAt,
  onValue,
  onChildAdded,
  onChildRemoved,
  push,
  set,
  update,
  remove,
  child,
  Unsubscribe as FirebaseUnsubscribe
} from '@firebase/database';
import { ref as storageRef, uploadString, getDownloadURL } from "firebase/storage";
import { store } from '@/store';
import { ImageType } from "@/utils/form";

export type Unsubscribe = FirebaseUnsubscribe;

export type ChatData = {
  chat_id: string;
  type: string;
  competition_id?: number;
  match_id?: number;
  chat_enabled_end_date_timeout?: number;
  to_user?: { avatar: string, username: string, id: number };
  send_msg_disabled?: boolean;
  blocked?: boolean;
  is_staff?: boolean;
  cost_inscription?: number;
  cost_inscription_promo?: number;
  game_icon?: string;
  game_name?: string;
};

export type ChatLabelMessageValue = {text?: string, labelId?: string, money?:number};

export type ChatLabelMessage = {
  labelId: string;
  values?: Record<string, ChatLabelMessageValue>;
};

export class ChatMessage {
  key?: string;
  messageId: string;
  userId: string | number;
  userName: string;
  userAvatar?: string;
  toUserId?: string | number;
  time: number | string;
  message: string;
  isImage: boolean;
  isStaff?: boolean;
  premiumCode?: string;
  isLabel?: boolean;
}

export class LastChatMessage {
  messageId: string;
  userId: number;
  fromUserId: number;
  userName: string;
  userAvatar?: string;
  message: string|ChatLabelMessage;
  time: number | string;
  isRead: boolean;
  isBlocked?: boolean;
  isImage?: boolean;
}

export class CompetitionLastChatMessage extends LastChatMessage {
  competitionId?: number|string;
  matchId?: number|string;
  costInscription: number|string;
  costInscriptionPromo: number|string;
  gameName: string;
  gameIcon: string;
  isRef?: boolean;
}

export const CHAT_TYPES = {
  WELCOME: 'welcome',
  GENERAL: 'general',
  GAMES: 'games',
  USERS: 'users',
  DOUBTS: 'doubts',
  COMPETITION: 'competition',
};

const chatKeyPrefix = 'chats';
const imagesKeyPrefix = 'imgsChat';
export const defaultChatLimit = 20;

export const getChatMessages = (roomChat: string, lastKey: string = null, limit = defaultChatLimit): Promise<ChatMessage[]> => {
  const firebaseDb = getFirebaseDb();
  const key = getKey(roomChat);
  if (!firebaseDb || !key) {
    return Promise.reject();
  }

  const args = [orderByKey()];
  if (lastKey) {
    args.push(limitToLast(limit + 1));
    args.push(endAt(lastKey));
  } else {
    args.push(limitToLast(limit));
  }

  const dbRef = query(ref(firebaseDb, key), ...args);

  return new Promise<ChatMessage[]>(resolve => {
    onValue(dbRef, snapshot => {
      const values = snapshot.val();
      if (lastKey && values[lastKey]) {
        delete values[lastKey];
      }
      resolve(values);
    }, {onlyOnce: true});
  });
};

export const onChatNewMessage = (roomChat: string, onChatNewMessageCallback: (data: { val: ChatMessage, key: string, prev: string }) => void): Promise<Unsubscribe> => {
  const firebaseDb = getFirebaseDb();
  const key = getKey(roomChat);
  if (!firebaseDb || !key) {
    return Promise.reject();
  }

  const dbRef = query(ref(firebaseDb, key), orderByKey(), limitToLast(1));

  return Promise.resolve(
    onChildAdded(dbRef, (snapshot, prevChildKey) => {
      onChatNewMessageCallback({val: snapshot.val(), key: snapshot.key, prev: prevChildKey});
    })
  );
};

export const onChatMessageRemove = (roomChat: string, onChatMessageRemoveCallback: (data: { val: ChatMessage, key: string }) => void): Promise<Unsubscribe> => {
  const firebaseDb = getFirebaseDb();
  const key = getKey(roomChat);
  if (!firebaseDb || !key) {
    return Promise.reject();
  }

  const dbRef = query(ref(firebaseDb, key), orderByKey());

  return Promise.resolve(
    onChildRemoved(dbRef, snapshot => {
      onChatMessageRemoveCallback({val: snapshot.val(), key: snapshot.key});
    })
  );
};

export const onUserLastMessagesChange = (userId: number, onChange: (lastMessages: LastChatMessage[]) => void = null): Promise<Unsubscribe> => {
  const firebaseDb = getFirebaseDb();
  const usersLastMessagesKeyPrefix = store.getters.appUsersLastMessagesKeyPrefix;
  const key = usersLastMessagesKeyPrefix ? getKey(`${usersLastMessagesKeyPrefix}${userId}`) : null;
  if (!firebaseDb || !key) {
    return Promise.reject();
  }

  const dbRef = query(ref(firebaseDb, key), orderByChild('time'));

  return Promise.resolve(
    onValue(dbRef, snapshot => {
      const values: LastChatMessage[] = [];
      snapshot.forEach(snapshotChild => {
        const value = snapshotChild.val() || null;
        if (value) {
          values.unshift(value);
        }
      });

      if (onChange) {
        onChange(values);
      }
    })
  );
};

export const sendUsersLastMessage = (msg: LastChatMessage, userId: number, toUserId: number): Promise<boolean> => {
  const firebaseDb = getFirebaseDb();
  const usersLastMessagesKeyPrefix = store.getters.appUsersLastMessagesKeyPrefix;
  const key = usersLastMessagesKeyPrefix ? getKey(`${usersLastMessagesKeyPrefix}${userId}/${toUserId}`) : null;
  if (!firebaseDb || !key) {
    return Promise.reject();
  }

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      set(ref(firebaseDb, key), msg)
        .then(() => resolve(true))
        .catch(() => reject());
    }, Math.random() * 500);
  });
};

export const onUserCompetitionsLastMessagesChange = (userId: number, onChange: (lastMessages: CompetitionLastChatMessage[]) => void = null): Promise<Unsubscribe> => {
  const firebaseDb = getFirebaseDb();
  const usersLastMessagesKeyPrefix = store.getters.appUsersLastMessagesKeyPrefix;
  const key = usersLastMessagesKeyPrefix ? getKey(`${usersLastMessagesKeyPrefix}competitions_${userId}`) : null;
  if (!firebaseDb || !key) {
    return Promise.reject();
  }

  const dbRef = query(ref(firebaseDb, key), orderByChild('time'));

  return Promise.resolve(
    onValue(dbRef, snapshot => {
      const values: CompetitionLastChatMessage[] = [];
      snapshot.forEach(competitionData => {
        const competitionId = competitionData.key;
        competitionData.forEach(matchData => {
          const matchId = matchData.key;
          const matchValue = matchData.val();
          matchValue.competitionId = competitionId;
          matchValue.matchId = matchId;
          values.unshift(matchValue);
        });
      });

      if (onChange) {
        onChange(values);
      }
    })
  );
};

export const sendUserCompetitionLastMessage = (msg: CompetitionLastChatMessage, userId: number, competitionId: number, matchId: number): Promise<boolean> => {
  const firebaseDb = getFirebaseDb();
  const usersLastMessagesKeyPrefix = store.getters.appUsersLastMessagesKeyPrefix;
  const key = usersLastMessagesKeyPrefix ? getKey(`${usersLastMessagesKeyPrefix}competitions_${userId}/${competitionId}/${matchId}`) : null;
  if (!firebaseDb || !key) {
    return Promise.reject();
  }

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      set(ref(firebaseDb, key), msg)
        .then(() => resolve(true))
        .catch(() => reject());
    }, Math.random() * 500);
  });
};

export const removeLastMessage = (userId: number, toUserId: number): Promise<boolean> => {
  const firebaseDb = getFirebaseDb();
  const usersLastMessagesKeyPrefix = store.getters.appUsersLastMessagesKeyPrefix;
  const key = usersLastMessagesKeyPrefix ? getKey(`${usersLastMessagesKeyPrefix}${userId}/${toUserId}`) : null;
  if (!firebaseDb || !key) {
    return Promise.reject();
  }

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      remove(ref(firebaseDb, key))
        .then(() => resolve(true))
        .catch(() => reject());
    }, Math.random() * 500);
  });
};

export const markUsersLastMessageAsRead = (userId: number, toUserId: number): Promise<boolean> => {
  const firebaseDb = getFirebaseDb();
  const usersLastMessagesKeyPrefix = store.getters.appUsersLastMessagesKeyPrefix;
  const key = usersLastMessagesKeyPrefix ? getKey(`${usersLastMessagesKeyPrefix}${userId}/${toUserId}`) : null;
  if (!firebaseDb || !key) {
    return Promise.reject();
  }

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const dbRef = ref(firebaseDb, key);
      onValue(dbRef, snapshot => {
        if (snapshot.exists()) {
          update(dbRef, {isRead: true})
            .then(() => resolve(true))
            .catch(() => reject());
        }
      }, {onlyOnce: true});
    }, Math.random() * 500);
  });
};

export const markUsersCompetitionLastMessageAsRead = (userId: number, competitionId: number, matchId: number): Promise<boolean> => {
  const firebaseDb = getFirebaseDb();
  const usersLastMessagesKeyPrefix = store.getters.appUsersLastMessagesKeyPrefix;
  const key = usersLastMessagesKeyPrefix ? getKey(`${usersLastMessagesKeyPrefix}competitions_${userId}/${competitionId}/${matchId}`) : null;
  if (!firebaseDb || !key) {
    return Promise.reject();
  }

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const dbRef = ref(firebaseDb, key);
      onValue(dbRef, snapshot => {
        if (snapshot.exists()) {
          update(dbRef, {isRead: true})
            .then(() => resolve(true))
            .catch(() => reject());
        }
      }, {onlyOnce: true});
    }, Math.random() * 500);
  });
};

export const isUsersLastMessageRead = (userId: number, toUserId: number): Promise<boolean> => {
  const firebaseDb = getFirebaseDb();
  const usersLastMessagesKeyPrefix = store.getters.appUsersLastMessagesKeyPrefix;
  const key = usersLastMessagesKeyPrefix ? getKey(`${usersLastMessagesKeyPrefix}${userId}/${toUserId}`) : null;
  if (!firebaseDb || !key) {
    return Promise.reject();
  }

  const dbRef = child(ref(firebaseDb, key), 'isRead');

  return new Promise(resolve => {
    onValue(dbRef, snapshot => {
      if (snapshot.exists()) {
        resolve(snapshot.val());
      } else {
        resolve(true);
      }
    }, {onlyOnce: true});
  });
};

export const sendMsg = (message: ChatMessage, chatId: string): Promise<boolean> => {
  const firebaseDb = getFirebaseDb();
  const key = getKey(chatId);
  if (!firebaseDb || !key) {
    return Promise.reject();
  }

  const newMessageRef = push(ref(firebaseDb, key));

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      set(newMessageRef, message)
        .then(() => resolve(true))
        .catch(() => reject());
    }, Math.random() * 500);
  });
};

export const removeMsg = (chatId: string, msgKey: string): Promise<boolean> => {
  const firebaseDb = getFirebaseDb();
  const key = getKey(`${chatId}/${msgKey}`);
  if (!firebaseDb || !key) {
    return Promise.reject();
  }

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      remove(ref(firebaseDb, key))
        .then(() => resolve(true))
        .catch(() => reject());
    }, Math.random() * 500);
  });
};

export const saveImage = (imageData: ImageType, roomChat = 'default', userId: number = null): Promise<string> => {
  const firebaseStorage = getFirebaseStorage();
  const key = getImageKey(roomChat);
  if (!firebaseStorage || !key) {
    return Promise.reject();
  }

  return new Promise((resolve, reject) => {
    const imageName = `${Date.now().toString()}_${userId}_${imageData.name}`;
    const imageStorageRef = storageRef(firebaseStorage, `${key}/${imageName}`);
    uploadString(imageStorageRef, imageData.image, 'data_url', {contentType: imageData.type})
      .then(uploadResult => {
        getDownloadURL(uploadResult.ref)
          .then(resolve)
          .catch(reject);
      })
      .catch(reject);
  });
};

const getKey = (partialKey: string): string | null => {
  let key = null;
  const appAlias = store.getters.appAlias;
  if (appAlias !== null) {
    key = `${chatKeyPrefix}${appAlias}/${partialKey}`;
  }

  return key;
};

const getImageKey = (partialKey: string): string | null => {
  let key = null;
  const appAlias = store.getters.appAlias;
  if (appAlias !== null) {
    key = `${imagesKeyPrefix}${appAlias}/${partialKey}`;
  }

  return key;
};

export const onConnectionChanged = (callback: (connected: boolean) => void, cancelCallback?: (error: Error) => unknown): Unsubscribe|null => {
  const firebaseDb = getFirebaseDb();
  if (firebaseDb) {
    return onValue(ref(firebaseDb, '.info/connected'), snap => callback(Boolean(snap.val())), cancelCallback);
  }

  return null;
};

export const isChatConnected = (): Promise<boolean> => {
  const firebaseDb = getFirebaseDb();
  if (!firebaseDb) {
    return Promise.resolve(false);
  }

  return new Promise<boolean>(resolve => {
    const timeoutHandle = setTimeout(() => resolve(false), 6000);
    onValue(ref(firebaseDb, 'onlineStatus'), snap => {
            clearTimeout(timeoutHandle);
            resolve(Boolean(snap.val()));
          }, () => {
            clearTimeout(timeoutHandle);
            resolve(false);
      }, { onlyOnce: true });
  });
};


  import Vue from "vue";

  export default Vue.extend({
    name: "pwa-theme",
    computed: {
      colors() {
        return this.$store.state.app?.theme?.colors || {};
      },
      ownStyle() {
        return this.$store.state.app?.theme?.ownStyle || '';
      },
    },
  });


  import Vue from 'vue';

  export default Vue.extend({
    name: "pwa-deposit-offer",
    props: {
      signUpButton: {
        type: Boolean,
        default: false,
        required: true
      }
    },
    methods: {
      signUp() {
        this.goToSignup();
      },
    }
  });

import { app } from '@/main';
import { loadScript } from '@/utils/common';
import { AppActionTypes } from '@/store/modules/app/actions';
import moment from 'moment-timezone';
import { isNativeApp } from '@/utils/native-app';
import {
  hideBannerNativeApp,
  showBannerNativeApp,
  showInterstitialNativeApp, showRewardedNativeApp
} from '@/utils/native-app/native-app-ads';
import { AuthActionTypes } from '@/store/modules/auth/actions';
import { ROUTES } from '@/router/routes';
import { loadAbBlockingRecovery } from '@/services/ads/ad-blocking-recovery/ad-blocking-recovery';
import { MODAL_IDS } from '@/utils/modal';

export const MOBILE_TOP_BANNER_HIDDEN_PAGES = {
  [ROUTES.login.name]: true,
  [ROUTES.signup.name]: true,
};

export type WEB_ADS_SLOTS_TYPE = {
  adUnitId: string;
  adSize?: {width: number, height: number};
};

export const WEB_ADS_SLOTS = {
  MOBILE_TOP_BANNER: {
    adUnitId: process.env.VUE_APP_WEB_MOBILE_TOP_BANNER_UNIT_ID,
    adSize: {width: 300, height: 50} as {width: number, height: number},
  },
  TABLET_TOP_BANNER: {
    adUnitId: process.env.VUE_APP_WEB_TABLET_TOP_BANNER_UNIT_ID,
    adSize: {width: 300, height: 100} as {width: number, height: number},
  },
  DESKTOP_LEADERBOARD: {
    adUnitId: process.env.VUE_APP_WEB_DESKTOP_LEADERBOARD_BANNER_UNIT_ID,
    adSize: {width: 728, height: 90} as {width: number, height: number},
  },
};

const interstitialInterval = 300; // seconds
let videoInterstitialLastSeen: moment.Moment;

export const loadInterstitialLastSeen = (): void => {
  const value = app.$store.getters.videoInterstitialSeen;
  if (value) {
    const lastSeen = moment.utc(value);
    if (lastSeen.isValid()) {
      videoInterstitialLastSeen = lastSeen;
    } else {
      updateInterstitialSeen();
    }
  } else {
    updateInterstitialSeen();
  }
};

const updateInterstitialSeen = () => {
  videoInterstitialLastSeen = moment.utc();
  app.$store.dispatch(AuthActionTypes.SET_VIDEO_INTERSTITIAL_SEEN, videoInterstitialLastSeen.toISOString());
};

const canShowInterstitial = (): boolean => {
  let canShow = false;

  if (app.hasAds) {
    canShow = true;
    if (videoInterstitialLastSeen) {
      const now = moment.utc();
      if (now.isSameOrAfter(videoInterstitialLastSeen)) {
        const lastSeen = videoInterstitialLastSeen.clone();
        lastSeen.add(interstitialInterval, 'seconds');
        canShow = lastSeen.isSameOrBefore(now);
      }
    }
  }

  return canShow;
};

export const hasAds = Boolean(process.env.VUE_APP_HAS_ADS);

export const initWebAds = (): Promise<boolean> => {
  if (app.$store.getters.adsLoaded) {
    return Promise.resolve(true);
  } else {
    return process.env.VUE_APP_WEB_ADSENSE_CLIENT_ID
      ? loadScript(`https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${process.env.VUE_APP_WEB_ADSENSE_CLIENT_ID}`, {crossOrigin: 'anonymous'})
        .then(() => {
          app.$store.dispatch(AppActionTypes.SET_ADS_LOADED, true);
          window.googlefc = window.googlefc || {};
          window.googlefc.callbackQueue = window.googlefc.callbackQueue || [];
          window.googlefc.callbackQueue.push({'AD_BLOCK_DATA_READY': () => {
            if (!window.googlefc.getAllowAdsStatus()) {
              app.showModalById(MODAL_IDS.ALLOW_ADS_MODAL);
            }
          }});

          new MutationObserver((mutations) => {
            for (const mutation of mutations) {
              for (const element of mutation.removedNodes) {
                if ((element as HTMLElement).classList.contains('fc-consent-root')) {
                  setTimeout(() => {
                    if (!window.googlefc.getAllowAdsStatus()) {
                      app.showModalById(MODAL_IDS.ALLOW_ADS_MODAL);
                    }
                  }, 1000);
                }
              }
            }
          }).observe(document.body, { childList: true });

          loadAbBlockingRecovery();
          return true;
        })
        .catch(() => {
          loadAbBlockingRecovery();

          return false;
        })
      : Promise.resolve(false);
  }
};

export const showMobileTopBanner = (): void => {
  if (hasAds && isNativeApp) {
    showBannerNativeApp();
  }
};

export const hideMobileTopBanner = (): void => {
  if (hasAds && isNativeApp) {
    hideBannerNativeApp();
  }
};

export const showInterstitial = async (force = true): Promise<boolean> => {
  if (hasAds && isNativeApp) {
    if (canShowInterstitial() || force) {
      return showInterstitialNativeApp().then(showed => {
        if (showed && !force) {
          updateInterstitialSeen();
        }

        return showed;
      });
    }
  }

  return Promise.resolve(false);
};

export const showRewarded = async (force = true): Promise<boolean> => {
  if (hasAds) {
    if (app.hasAds || force) {
      if (isNativeApp) {
        return showRewardedNativeApp();
      }
    }
  }

  return Promise.resolve(false);
};

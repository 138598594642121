import { Module } from 'vuex';
import { State } from "@/store/types";
import state, { UserSourceState } from './state';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

const userSource: Module<UserSourceState, State> = {
  state: () => state,
  mutations,
  actions,
  getters,
};

export default userSource;

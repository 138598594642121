import { Module } from 'vuex';
import { State } from "@/store/types";
import state, { AuthState } from './state';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

const auth: Module<AuthState, State> = {
  state: () => state,
  mutations,
  actions,
  getters,
};

export default auth;

import { Module } from 'vuex';
import { State } from "@/store/types";
import state, { ModalsSeenState } from './state';
import mutations from './mutations';
import actions from './actions';

const modalsSeen: Module<ModalsSeenState, State> = {
  state: () => state,
  mutations,
  actions,
};

export default modalsSeen;


  import Vue from 'vue';
  import {getCommonImageFolderName} from "@/utils/common";

  const icons: any = {};
  const commonImageFolderName = getCommonImageFolderName();

  const iconComponentName = (name: string) => `pwa-${name}-icon`;
  const requireComponents = require.context('../../assets/img/common/icons/', false, /[\w]\.svg$/);

  requireComponents.keys().forEach(fileName => {
    const iconName = fileName.replace(/^\.\/(.+)\.svg/, '$1');
    icons[iconComponentName(iconName)] = () => import(`@/assets/img/${commonImageFolderName}/icons/${iconName}.svg?inline`);
  });

  export default Vue.extend({
    name: "pwa-icon",
    props: {
      name: {
        type: String,
        required: true,
      },
    },
    components: {...icons},
    computed: {
      iconComponent() {
        return icons[iconComponentName(this.name)]
      }
    },
    inheritAttrs: false,
  });

import { CompetitionLastChatMessage, LastChatMessage } from "@/services/chat";

export type UserState = {
  wallet: {
    quantity: number,
    free_inscriptions_quantity: number,
    has_free_inscriptions: boolean,
    has_deposit: boolean,
  };
  games: Array<any>;
  minigames: Array<any>;
  chat: {
    lastMessages: Array<LastChatMessage>,
    hasLastMessagesUnreadMessages: boolean,
    competitionsLastMessages: Array<CompetitionLastChatMessage>,
    hasCompetitionsLastMessagesUnreadMessages: boolean,
    connected: boolean,
  };
};

const state: UserState = {
  wallet: {
    quantity: 0,
    free_inscriptions_quantity: 0,
    has_free_inscriptions: false,
    has_deposit: false,
  },
  games: [],
  minigames: [],
  chat: {
    lastMessages: null,
    hasLastMessagesUnreadMessages: false,
    competitionsLastMessages: null,
    hasCompetitionsLastMessagesUnreadMessages: false,
    connected: false,
  },
};

export default state;

import { MutationTree } from 'vuex/types';
import { ModalsSeenState } from './state';

export const ModalsSeenMutationTypes = {
  SET_MODAL_LAST_SEEN: '@modalsSeen/SET_MODAL_LAST_SEEN',
};

const mutations: MutationTree<ModalsSeenState> = {
  [ModalsSeenMutationTypes.SET_MODAL_LAST_SEEN](modalsSeen: ModalsSeenState, {modalName, lastSeen}) {
    modalsSeen[modalName] = lastSeen;
  },
};

export default mutations;

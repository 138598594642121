
  import Vue from "vue";
  import PwaIcon from "@/components/common/PwaIcon.vue";

  export default Vue.extend({
    name: "pwa-loader",
    components: {
      PwaIcon,
    },
  });

import { render, staticRenderFns } from "./PwaGameHeader.vue?vue&type=template&id=b0c57702&scoped=true&"
import script from "./PwaGameHeader.vue?vue&type=script&lang=ts&"
export * from "./PwaGameHeader.vue?vue&type=script&lang=ts&"
import style0 from "./PwaGameHeader.vue?vue&type=style&index=0&id=b0c57702&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0c57702",
  null
  
)

export default component.exports
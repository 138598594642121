
  import Vue from 'vue';
  import PwaSlider from "@/components/common/PwaSlider.vue";
  import {ROUTES} from "@/router/routes";

  export default Vue.extend({
    name: "pwa-all-minigames",
    props: {
      games: {
        type: [Array, Object],
        required: true
      },
      signUp: {
        type: Function
      },
      sliderAutoplayDelay: {
        type: Number,
        required: false,
        default: 0,
      },
    },
    components: {
      PwaSlider
    },
    data() {
      return {
        minigameRouteName: ROUTES.minigame.name,
      }
    }
  });

export type UserSourceState = {source?: string, medium?: string, campaign?: string, content?: string};

const state: UserSourceState = {
  source: null,
  medium: null,
  campaign: null,
  content: null,
};

export default state;


  import Vue from "vue";
  import PwaCountdownMessage from "@/components/common/PwaCountdownMessage.vue";
  import PwaPrice from "@/components/common/PwaPrice.vue";
  import { COMPETITION_STATUS_ID, getPlatformIcon } from "@/utils/competition";
  import {ROUTES} from "@/router/routes";
  import PwaIcon from "@/components/common/PwaIcon.vue";
  import PwaUserLevel from "@/components/user/PwaUserLevel.vue";

  export default Vue.extend({
    name: "pwa-competition-card",
    components: {
      PwaCountdownMessage,
      PwaPrice,
      PwaIcon,
      PwaUserLevel,
    },
    props: {
      competition: Object
    },
    data() {
      return {
        competitionsStatusId: COMPETITION_STATUS_ID,
      }
    },
    methods: {
      getPlatformIcon,
      onCompetitionCardClick(): void {
        this.$router.push({name: ROUTES.competition.name, params: {lang: this.routeLang, competitionId: this.competition.id}});
      },
      getUserData() {
        let user = this.competition.owner;
        // Mostramos los datos del oponente solo si el creador es el mismo usuario
        if(this.competition.owner.user_id == this.userId && this.competition.opponent){
          user = this.competition.opponent;
        }

        return user;
      }
    },
  });

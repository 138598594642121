export type AuthState = {
  acceptCookies: boolean | false;
  friendCode: string | null
  token: string | null;
  firebaseToken: string | null;
  user: {
    lang?: string,
    id?: number,
    avatar?: string,
    username?: string,
    email?: string,
    timezone?: { value: string, name: string },
  };
  videoInterstitialSeen: string,
};

const state: AuthState = {
  acceptCookies: false,
  friendCode: null,
  token: null,
  firebaseToken: null,
  user: {},
  videoInterstitialSeen: '',
};

export default state;

import { GetterTree } from 'vuex/types';
import { State } from "@/store/types";
import { NotificationCenterState } from "./state";

const getters: GetterTree<NotificationCenterState, State> = {
  hasNotificationCenterAlert: notificationCenter => (notificationCenter.numActiveCompetitions > 0 || notificationCenter.numCompetitionProposals > 0 ),
  hasUnreadNotifications: ({notifications}) => (Boolean(notifications?.all?.num_unread)),
};

export default getters;

import { Middleware } from "@/router/types";
import { ROUTES } from "@/router/routes";
import { AppActionTypes } from "@/store/modules/app/actions";

export const auth: Middleware = ({to, next, store}) => {
  if (!store.getters.isLoggedIn) {
    if (to.name === ROUTES.profile.name || to.name === ROUTES.competition.name || to.name === ROUTES.multiplayer.name || to.name === ROUTES.ranked.name || to.name === ROUTES.promo.name || to.name === ROUTES.deposit.name) {
      store.dispatch(AppActionTypes.SET_NEXT_URL, to.fullPath);
      if (store.getters.isDesktop) {
        next({name: ROUTES.home.name, query: to.query, params: to.params});
      } else {
        next({name: ROUTES.login.name, query: to.query, params: to.params});
      }
    } else {
      next({name: ROUTES.home.name, query: to.query, params: to.params});
    }
  } else {
    next();
  }
};

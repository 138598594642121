
  import Vue from 'vue';
  import PwaAllGames from '@/components/game/PwaAllGames.vue';

  export default Vue.extend({
    name: "pwa-platform-advantages",
    components: {
      PwaAllGames,
    },
    props: {
      games: {
        type: Array,
        default: [] as any,
        required: true
      },
      gameCategories: {
        type: Array,
        default: [] as any,
        required: true
      },
      showGameName: {
        type: Boolean,
        required: false,
        default: false,
      },
      showGamePlatform: {
        type: Boolean,
        required: false,
        default: false,
      },
      allowNavigateToGame: {
        type: Boolean,
        required: false,
        default: false,
      },
      showAllGamesModes: {
        type: Boolean,
        required: false,
        default: false,
      },
      showGamesList: {
        type: Boolean,
        required: false,
        default: false,
      },
    }
  });

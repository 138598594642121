import { MutationTree } from 'vuex/types';
import { UserSourceState } from './state';

export const UserSourceMutationTypes = {
  SET_USER_SOURCE: '@userSource/SET_USER_SOURCE',
};

const mutations: MutationTree<UserSourceState> = {
  [UserSourceMutationTypes.SET_USER_SOURCE](userSource: UserSourceState, {source, medium, campaign, content}) {
    userSource.source = source || null;
    userSource.medium = medium || null;
    userSource.campaign = campaign  || null;
    userSource.content = content  || null;
  },
};

export default mutations;

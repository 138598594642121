import { Module } from 'vuex';
import { State } from "@/store/types";
import state, { NotificationCenterState } from './state';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

const notificationCenter: Module<NotificationCenterState, State> = {
  state: () => state,
  mutations,
  actions,
  getters,
};

export default notificationCenter;

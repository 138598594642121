
  import Vue, {PropType} from "vue";

  export default Vue.extend({
    name: "pwa-game-header",
    components: {
      PwaGamesSlider: () => import("@/components/game/PwaGamesSlider.vue"),
    },
    props: {
      gameData: {
        type: Object,
        required: true,
      },
      gameConfigured: {
        type: Boolean,
        required: true,
      },
      onGameConfiguredSuccess: {
        type: Function as PropType<() => void>,
        required: false,
        default: null,
      },
      games: {
        type: Array,
        required: false,
        default: null,
      },
    },
  });

import { Module } from 'vuex';
import { State } from "@/store/types";
import state, { SocialLoginState } from './state';
import mutations from './mutations';
import actions from './actions';

const socialLogin: Module<SocialLoginState, State> = {
  state: () => state,
  mutations,
  actions,
};

export default socialLogin;

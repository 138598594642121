
  import Vue from "vue";
  import PwaIcon from "@/components/common/PwaIcon.vue";

  export default Vue.extend({
    name: "pwa-short-avatars-list",
    components: {
      PwaIcon,
    },
    props: {
      usersList: {
        type: Array,
        default: [] as any[],
        required: false,
      },
      numAvatars: {
        type: Number,
        default: 3,
        required: false,
      },
    },
    computed: {
      usersListFiltered(): any[] {
        return this.usersList.slice(0, this.numAvatars);
      },
      numAvatarsRemaining(): number {
        const numAvatarsRemaining = this.usersList.length - this.numAvatars;
        return numAvatarsRemaining > 0 ? numAvatarsRemaining : 0;
      },
    },
  });

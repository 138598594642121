import { UserSourceState } from '@/store/modules/userSource/state';
import { Dictionary } from 'vue-router/types/router';

const SOURCE_PARAM = 'utm_source';
const CAMPAIGN_PARAM = 'utm_campaign';
const MEDIUM_PARAM = 'utm_medium';
const CONTENT_PARAM = 'utm_content';

const URL_PARAMS = [SOURCE_PARAM, CAMPAIGN_PARAM, MEDIUM_PARAM, CONTENT_PARAM];
const PARAMS_VALUES: any = {
  [SOURCE_PARAM]: 'source',
  [CAMPAIGN_PARAM]: 'campaign',
  [MEDIUM_PARAM]: 'medium',
  [CONTENT_PARAM]: 'content',
};

export const getUrlUserSourceParams = (query: Dictionary<string | (string | null)[]>): UserSourceState|null => {
  let params: any = null;
  URL_PARAMS.forEach(urlParam => {
    const param = PARAMS_VALUES[urlParam];
    if (query[urlParam] && param) {
      if (params === null) {
        params = {};
      }

      params[param] = query[urlParam];
    }
  });
  return params;
};

import { MutationTree } from 'vuex/types';
import { NotificationCenterState } from './state';

export const NotificationCenterMutationTypes = {
  SET_NOTIFICATION_CENTER: '@notificationCenter/SET_NOTIFICATION_CENTER',
};

const mutations: MutationTree<NotificationCenterState> = {
  [NotificationCenterMutationTypes.SET_NOTIFICATION_CENTER](notificationCenter: NotificationCenterState, data: NotificationCenterState) {
    Object.assign(notificationCenter, data);
  },
};

export default mutations;

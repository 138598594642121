import { ActionTree } from 'vuex';
import { State } from "@/store/types";
import { NotificationCenterMutationTypes } from './mutations';
import { NotificationCenterState } from './state';
import { app } from '@/main';

export const NotificationCenterActionTypes = {
  UPDATE_NOTIFICATION_CENTER: '@notificationCenter/UPDATE_NOTIFICATION_CENTER',
  SET_NOTIFICATION_CENTER: '@notificationCenter/SET_NOTIFICATION_CENTER',
  CLEAR_NOTIFICATION_CENTER: '@notificationCenter/CLEAR_NOTIFICATION_CENTER',
};
const actions: ActionTree<NotificationCenterState, State> = {
  [NotificationCenterActionTypes.UPDATE_NOTIFICATION_CENTER]({dispatch}) {
    return new Promise(resolve => {
      app.$http.notificationCenter.getNotificationCenter().then(({data}) => {
        const notificationCenter: NotificationCenterState = {
          numCompetitionProposals: data.data.num_competition_proposals || 0,
          numActiveCompetitions: data.data.num_active_competitions || 0,
          numUnreadNotifications: data.data.num_unread_notifications || 0,
          notifications: data.data.notifications || {},
        };
        dispatch(NotificationCenterActionTypes.SET_NOTIFICATION_CENTER, notificationCenter);
        resolve(true);
      }).catch(() => {
        dispatch(NotificationCenterActionTypes.CLEAR_NOTIFICATION_CENTER);
        resolve(true);
      });
    });
  },
  [NotificationCenterActionTypes.SET_NOTIFICATION_CENTER]({commit}, data: NotificationCenterState) {
    return new Promise(resolve => {
      commit(NotificationCenterMutationTypes.SET_NOTIFICATION_CENTER, data);
      resolve(true);
    });
  },
  [NotificationCenterActionTypes.CLEAR_NOTIFICATION_CENTER]({commit}) {
    return new Promise(resolve => {
      commit(NotificationCenterMutationTypes.SET_NOTIFICATION_CENTER, {
        numCompetitionProposals: 0,
        numActiveCompetitions: 0,
      });
      resolve(true);
    });
  },
};

export default actions;

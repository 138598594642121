import { NotificationCenterNotificationListType } from '@/utils/notification-center';

const state = {
  numCompetitionProposals: 0,
  numActiveCompetitions: 0,
  numUnreadNotifications: 0,
  notifications: {} as NotificationCenterNotificationListType,
};

export type NotificationCenterState = typeof state;

export default state;

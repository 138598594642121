import { getBrandFolderName } from '@/utils/common';

const publisherId = process.env.VUE_APP_WEB_ADSENSE_CLIENT_ID.replace('ca-', '');
const nonce = process.env.VUE_APP_WEB_ADSENSE_AD_BLOCKING_RECOVERY_NONCE;

export const loadAbBlockingRecovery = (): void => {
  if (publisherId && nonce) {
    const script: HTMLScriptElement = document.createElement('script');
    script.async = true;
    script.src = `https://fundingchoicesmessages.google.com/i/${publisherId}?ers=1`;
    script.nonce = nonce;
    script.setAttribute('nonce', nonce);

    script.onerror = () => {
      // Nothing to do
    };

    document.getElementsByTagName('head')[0].appendChild(script);
    loadSignalGooglefcPresentScript();
    loadErrorProtectionMessageScript();
  }
};

const loadSignalGooglefcPresentScript = (): void => {
  const script: HTMLScriptElement = document.createElement('script');
  script.nonce = nonce;
  script.setAttribute('nonce', nonce);
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  script.text = require('!raw-loader!./ad-blocking-recovery-script.js').default;

  document.getElementsByTagName('head')[0].appendChild(script);
};


const loadErrorProtectionMessageScript = (): void => {
  let fallbackScript = null;
  try {
    const brandName = getBrandFolderName();
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    fallbackScript = require(`!raw-loader!./fallbacks/${brandName}.js`);
  } catch (e) {
    // Nothing to do
  }

  if (fallbackScript) {
    const script: HTMLScriptElement = document.createElement('script');
    script.text = fallbackScript.default;
    document.getElementsByTagName('head')[0].appendChild(script);
  }
};

import breakpoints from '@/assets/css/breakpoints.scss';
import moment from "moment";
import { COMPETITION_MODE_IDS } from '@/utils/competition';
import { GAMES_URLS } from '@/utils/game';

export type CurrencyType = {
  symbol: string,
  isoCode: string,
  multiplier: number,
  decimals: number,
  symbolPosRight: boolean,
  icon?: string,
};

export type PriceType = {
  amount: number,
  currency: CurrencyType,
};

export const getDevice = (): { isDesktop: boolean, isTablet: boolean, isMobile: boolean } => {
  const innerWidth = window.innerWidth;
  let isDesktop = false;
  let isTablet = false;
  let isMobile = false;

  const prerenderDevice = window?.__PRERENDER_INJECTED?.device;

  if (prerenderDevice === 'desktop' || innerWidth >= parseInt(breakpoints[breakpoints.desktopBreakpoint])) {
    isDesktop = true;
  } else if (innerWidth >= parseInt(breakpoints[breakpoints.tabletBreakpoint])) {
    isTablet = true;
  } else {
    isMobile = true;
  }

  return {isDesktop, isTablet, isMobile};
};

export const isValidEmail = (email: string): boolean => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const getUrlImg = (segment: string, img: string): string => {
  const baseUrl: string = process.env.VUE_APP_IMAGES_BASE_URL || process.env.VUE_APP_API_BASE_URL;
  return `${baseUrl}/${segment}/${img}`;
};

const commonImageFolderName = 'common';
export const getCommonImageFolderName: () => string = () => commonImageFolderName;
export const getCommonImageSrc = (imagePath: string): string => require(`@/assets/img/${commonImageFolderName}/${imagePath}`);

const brandFolderName = process.env.VUE_APP_BRAND_FOLDER;
export const getBrandFolderName: () => string = () => brandFolderName;
export const getBrandImageSrc = (imagePath: string): string => require(`@/assets/img/${brandFolderName}/${imagePath}`);

export const getDefaultAvatar = (): string => getBrandImageSrc('avatar.svg');

export const containsUrl = (value: string): boolean => {
  const urls = getAllUrls(value);

  return urls && urls.length > 0;
};

export const containNotAllowedUrls = (value: string): boolean => {
  let hasUrls = false;
  const urls = getAllUrls(value);
  if (urls && urls.length > 0) {
    const outPwaUrl = urls.find(url => !isAllowUrl(url));
    hasUrls = Boolean(outPwaUrl);
  }

  return hasUrls;
};

export const getUrlDomain = (value: string): string => {
  const matches = value.match(/^(?:https?:\/\/)?(?:www\.)?([^/:?#]+)(?:[/:?#]|$)/i);

  return matches && matches[1] ? matches[1] : '';
};

export const pwaDomain = process.env.VUE_APP_PWA_URL ? getUrlDomain(process.env.VUE_APP_PWA_URL) : 'winnder.com';
export const pwaBaseUrl = process.env.VUE_APP_PWA_URL && process.env.VUE_APP_PWA_URL.charAt(process.env.VUE_APP_PWA_URL.length - 1) === '/'
  ? process.env.VUE_APP_PWA_URL.slice(0, -1)
  : location.origin;

const urlMatch = /(https?:\/\/(?:www\.|(?!www))[^\s.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})/gi;

export const getAllUrls = (value: string): RegExpMatchArray | null => value.match(urlMatch);

export const linkify = (value: string, seeMatchText = 'Ver partida'): string => value.replace(new RegExp(`(((http|https)://)(www.)?(${pwaDomain}|${Object.keys(GAMES_URLS).join('|')})(\\/\\S*)?)`, 'g'),  url => {
  const pwaDomainPosition = url.indexOf(pwaDomain);
  const partialUrl = pwaDomainPosition >= 0 ? url.substring(pwaDomainPosition + pwaDomain.length) : url;
  return url.includes('/competition/')
    ? `<a href="${partialUrl}" title="${url}">${seeMatchText}</a>`
    : `<a href="${partialUrl}" target="_blank" title="${url}">${url}</a>`;
});

export const isAllowUrl = (value: string): boolean => {
  const domain = getUrlDomain(value);

  return domain === pwaDomain || isGameDomain(domain);
};

export const isGameDomain = (domain: string): boolean => {
  return GAMES_URLS[domain];
};

export const formatPrice = (price: PriceType, addSymbol = false, removeDecimalsIfPossible = false): string => {
  const {amount, currency} = price;
  const currencyAmount = amount * currency.multiplier;
  const amountFormatted = removeDecimalsIfPossible ? `${Number.parseFloat(currencyAmount.toFixed(currency.decimals))}` : currencyAmount.toFixed(currency.decimals);

  let priceFormatted = '';
  if (addSymbol) {
    priceFormatted = currency.symbolPosRight
      ? `${amountFormatted}${currency.symbol}`
      : `${currency.symbol}${amountFormatted}`;
  } else {
    priceFormatted = amountFormatted;
  }

  return priceFormatted;
};

export const ordinal = (num: number): string => moment().localeData().ordinal(num);

export function debounce<T extends (...args: any[]) => void>(cb: T, wait = 200): T {
  let h: any = 0;
  const callable = (...args: any) => {
    clearTimeout(h);
    h = setTimeout(() => cb(...args), wait);
  };
  return <T>(<any>callable);
}

export const isObjectEmpty = (object: Record<string, unknown>): boolean => Object.keys(object).length == 0;

export const isIOS = /iphone|ipad|ipod|ios/.test(window.navigator.userAgent.toLowerCase());

export const isPwaInstalled = (): boolean => window.matchMedia('(display-mode: standalone)').matches;

let showInstallPrompt: any = null;
let showInstallPromptPromise: Promise<void> = null;
export const initPwaInstallPrompt = (): void => {
  showInstallPromptPromise = new Promise((resolve => {
    window.addEventListener('beforeinstallprompt', e => {
      e.preventDefault();
      showInstallPrompt = e;
      resolve();
    });
  }));
};

export const onBeforeInstallPrompt = (): Promise<void> => {
  return showInstallPrompt
    ? Promise.resolve()
    : showInstallPromptPromise;
};

export const showInstallPwaPrompt = (): Promise<string> => {
  if (showInstallPrompt) {
    showInstallPrompt.prompt();

    const installPrompt = showInstallPrompt;
    showInstallPrompt = null;

    return installPrompt.userChoice;
  }

  return Promise.reject();
};

export const canShowInstallPwaPrompt = (): boolean => Boolean(showInstallPrompt);

export const isPrerender = Boolean(window?.__PRERENDER_INJECTED?.device);

export const isRelativeUrl = (url: string): boolean => url.charAt(0) === '/';

export const getLastTimeMultiplayer = (): number => {
  // 30 min
  return 1800;
};

export const getLastTimeRanked = (mode: string): number => {
  // 10 min
  if(mode === COMPETITION_MODE_IDS.HOUR){
    return 600;
  // 1 hora
  } else if(mode === COMPETITION_MODE_IDS.DAILY){
    return 3600;
  // 3 horas
  } else if(mode === COMPETITION_MODE_IDS.WEEKLY){
    return 10800;
  // 3 días
  } else if(mode === COMPETITION_MODE_IDS.MONTHLY){
    return 259200;
  }
  return null;
};

export const loadScript = (scriptSrc: string, {async = true, defer = false, crossOrigin = ''} = {}): Promise<boolean> => new Promise((resolve, reject) => {
  const script: HTMLScriptElement = document.createElement('script');
  script.type = 'text/javascript';
  script.src = scriptSrc;
  if (async) {
    script.async = true;
  }

  if (defer) {
    script.defer = true;
  }

  if (crossOrigin) {
    script.crossOrigin = crossOrigin;
  }

  script.charset = 'utf-8';
  script.onload = () => {
    resolve(true);
  };

  script.onerror = () => {
    reject(true);
  };

  document.getElementsByTagName('head')[0].appendChild(script);
});

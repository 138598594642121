import { isPrerender, loadScript, pwaBaseUrl } from '@/utils/common';
import { ROUTES } from '@/router/routes';
import { isIosNativeApp, isTrackingTransparencyAllowed } from '@/utils/native-app';

let scriptLoaded = false;

export const CONSENT_UPDATE_EVENT_NAME = 'consent_update';

export const COOKIE_CONSENT_LEVELS = {
  TARGETING: 'targeting',
  FUNCTIONALITY: 'functionality',
  TRACKING: 'tracking',
  STRICTLY_NECESSARY: 'strictly-necessary',
};

const DEFAULT_USER_VALUES = {
  'ad_storage': 'denied',
  'ad_user_data': 'denied',
  'ad_personalization': 'denied',
  'analytics_storage': 'denied',
  'functionality_storage': 'denied',
  'personalization_storage': 'denied',
};

const VALUES_BY_LEVEL_ACCEPTED = {
  [COOKIE_CONSENT_LEVELS.TARGETING]: ['ad_storage', 'ad_user_data', 'ad_personalization'],
  [COOKIE_CONSENT_LEVELS.FUNCTIONALITY]: ['functionality_storage', 'personalization_storage'],
  [COOKIE_CONSENT_LEVELS.TRACKING]: ['analytics_storage'],
};

export const loadCookieConsentScript = (): Promise<boolean> => new Promise((resolve, reject) => {
  if (!isPrerender && process.env.VUE_APP_ADD_COOKIE_CONSENT) {
    loadScript(`${process.env.BASE_URL}js/cookie-consent.js`)
      .then(() => {
        if (document.readyState === "loading") {
          // Loading hasn't finished yet
          document.addEventListener("DOMContentLoaded", () => {
            scriptLoaded = true;
            resolve(true);
          });
        } else {
          scriptLoaded = true;
          resolve(true);
        }
      }).catch(() => reject());
  } else {
    reject();
  }
});

export type CookieConsentCallbackType = {
  'notice_banner_loaded'?: () => void,
  'i_agree_button_clicked'?: () => void,
  'i_decline_button_clicked'?: () => void,
  'change_my_preferences_button_clicked'?: () => void,
  'preferences_center_save_pressed'?: () => void,
  'scripts_specific_loaded'?: (level: string) => void,
  'scripts_all_loaded'?: () => void,
};

export const cookieConsentRun = (lang: string, callbacks: CookieConsentCallbackType): void => {
  if (scriptLoaded) {
    const termsfeed: HTMLElement = document.getElementById('termsfeed-com---nb');
    if (termsfeed) {
      termsfeed.remove();
      document.querySelectorAll('.termsfeed-com---reset').forEach(element => {
        element.remove();
      });
    }

    window.cookieconsent.run({
      "notice_banner_type": "headline",
      "consent_type": "express",
      "palette": "dark",
      "language": lang,
      "page_load_consent_levels": [COOKIE_CONSENT_LEVELS.STRICTLY_NECESSARY],
      "notice_banner_reject_button_hide": false,
      "preferences_center_close_button_hide": false,
      "page_refresh_confirmation_buttons": false,
      "website_privacy_policy_url": `${pwaBaseUrl}/${ROUTES.cookies.path}`,
      "website_impressum_url": true,
      "callbacks": callbacks,
      "callbacks_force": true,
    });
  }
};

export const openCookieConsentPreferencesCenter = (): void => {
  if (scriptLoaded) {
    window.cookieconsent.openPreferencesCenter();
  }
};

export const getCurrentCookieConsentValues = async (onlyGranted = false): Promise<Record<string, string>> => {
  const userValues: Record<string, string> = onlyGranted ? {} : {...DEFAULT_USER_VALUES};
  if (scriptLoaded) {
    const acceptedLevels = window.cookieconsent?.cookieConsentObject?.userConsent?.acceptedLevels || {};

    if (!onlyGranted) {
      if (acceptedLevels[COOKIE_CONSENT_LEVELS.TARGETING]) {
        if (isIosNativeApp) {
          acceptedLevels[COOKIE_CONSENT_LEVELS.TARGETING] = await isTrackingTransparencyAllowed();
        }
      }
    }

    Object.keys(acceptedLevels).forEach((level: string) => {
      if (acceptedLevels[level] && VALUES_BY_LEVEL_ACCEPTED[level]) {
        VALUES_BY_LEVEL_ACCEPTED[level].forEach((value: string) => {
          userValues[value] = 'granted';
        });
      }
    });
  }

  return userValues;
};

export const getAllGrantedCookieConsentValues = (): Record<string, string> => {
  const userValues: Record<string, string> = {};

  Object.keys(DEFAULT_USER_VALUES).forEach((value: string) => {
    userValues[value] = 'granted';
  });

  return userValues;
};

export const hasUserDefaultCookieConsentValues = (): boolean => {
  let isDefaultValues = true;
  if (scriptLoaded) {
    const acceptedLevels = window.cookieconsent?.cookieConsentObject?.userConsent?.acceptedLevels || {};
    for (const level in acceptedLevels) {
      if (level !== COOKIE_CONSENT_LEVELS.STRICTLY_NECESSARY && acceptedLevels[level]) {
        isDefaultValues = false;
        break;
      }
    }
  }

  return isDefaultValues;
};

import Vue from 'vue';
import VueI18n, { LocaleMessages } from 'vue-i18n';
import moment from 'moment-timezone';
import { setApiLang } from "@/services/api/api";
import { LANGS, APP_LANGS, getRouteLang, isBot } from '@/utils/i18n';
import VueRouter from 'vue-router';
import { getBrandFolderName } from '@/utils/common';

Vue.use(VueI18n);

const brandFolderName = getBrandFolderName();
const getLocaleMessages: () => LocaleMessages = () => {
  const messages: LocaleMessages = {};
  APP_LANGS.forEach(lang => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const globalMessages = require(`@/locales/${lang}.json`);
    let whiteLabelMessages = {};
    try {
      whiteLabelMessages = require(`@/locales/${brandFolderName}/${lang}.json`);
    } catch (e) {
      // Whitelabel has no own lang file
    }
    messages[lang] = {...globalMessages, ...whiteLabelMessages};
  });

  return messages;
};

const messages = getLocaleMessages();
const fallbackLocale: string = process.env.VUE_APP_I18N_FALLBACK_LOCALE || LANGS.EN;

const getLocale = (locale: string): string => {
  const prerenderLang = window?.__PRERENDER_INJECTED?.lang;
  if (prerenderLang) {
    return prerenderLang;
  } else {
    const localeLang = locale ? locale.split('-')[0] : LANGS.ES;

    return messages[localeLang] ? localeLang : fallbackLocale;
  }
};

const locale: string = getLocale(isBot ? null : navigator.language);

const i18n = new VueI18n({
  locale,
  fallbackLocale,
  messages,
});

moment.updateLocale(LANGS.ES, {
  longDateFormat: {
    LT: 'H:mm[h]',
    LTS: 'H:mm:ss',
    L: 'DD/MM/YYYY',
    LL: 'D [de] MMMM [de] YYYY',
    LLL: 'D [de] MMMM [de] YYYY H:mm',
    LLLL: 'dddd, D [de] MMMM [de] YYYY H:mm',
  },
});

moment.locale(locale);
setApiLang(locale);

export const setLocale = (locale: string, router: VueRouter = null): void => {
  const _locale = getLocale(locale);
  if (i18n.locale !== _locale) {
    moment.locale(_locale);
    setApiLang(_locale);
    i18n.locale = _locale;
  }

  if (router) {
    const currentRoute = router.currentRoute;
    const routeLang = getRouteLang(_locale);
    if (routeLang != currentRoute.params.lang) {
      const params = {...currentRoute.params, lang: routeLang};
      router.replace({...currentRoute, params});
    }
  }
};

export const setTimezone = (timezone: string = null): void => {
  if (timezone) {
    moment.tz.setDefault(timezone);
  } else {
    moment.tz.setDefault(moment.tz.guess());
  }
};

/**
 * Overloads VueI18n interface to avoid needing to cast return value to string.
 * @see https://github.com/kazupon/vue-i18n/issues/410
 */
declare module 'vue-i18n/types' {
  export default class VueI18n {
    t(key: Path, locale: Locale, values?: Values): string;
    t(key: Path, values?: Values): string;
  }
}

export default i18n;

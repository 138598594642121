import Vue from 'vue';
import App from './App.vue';
import router from './router';
import { store } from './store';
import './registerServiceWorker';
import * as api from '@/services/api';
import i18n, { setLocale, setTimezone } from '@/services/i18n'
import '@/services/bootstrap';
import '@/services/seo';
import '@/mixins/global';
import { AppActionTypes } from "@/store/modules/app/actions";
import './assets/css/app.scss';
import { initFirebase } from "@/services/firebase";
import { initPwaInstallPrompt } from '@/utils/common';

initFirebase();
Vue.config.productionTip = false;

const {token, user} = store.state.auth;
api.setAuthToken(token);
api.initApiInterceptors(router, store);
Vue.prototype.$http = api;
declare module 'vue/types/vue' {
  interface Vue {
    readonly $http: typeof api;
  }
}
const {lang, timezone} = user;
if (lang) {
  setLocale(lang);
}

const timezoneValue = timezone && timezone.value ? timezone.value : null;
setTimezone(timezoneValue);

export const app = new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  mounted: () => {
    if (window.__PRERENDER_INJECTED) {
      setTimeout(() => {
        // document.getElementById('app').setAttribute('data-server-rendered', 'true');
        document.head.querySelector('meta[data-pwa-meta-noindex="1"]').remove();
        if (window.__PAGE_INITIAL_DATA) {
          const script = document.createElement('script');
          script.type = 'text/javascript';
          script.text = `window.__PAGE_INITIAL_DATA=${JSON.stringify(window.__PAGE_INITIAL_DATA)}`;
          document.body.appendChild(script);
        }
        document.dispatchEvent(new Event("x-app-rendered"));
        }, 5000);
    }
  },
});
initPwaInstallPrompt();

api.config.getAppConfig()
  .then(({data}) => store.dispatch(AppActionTypes.SET_APP_CONFIG, data.data))
  .finally(() => app.$mount('#app'));

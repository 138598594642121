
  import Vue from "vue";

  export default Vue.extend({
    name: "pwa-no-competitions",
    props: {
      titleLabel: {
        type: String,
        required: false,
        default: '365',
      },
      showCreateButton: {
        type: Boolean,
        required: false,
        default: true,
      },
      gameConfigured: {
        type: Boolean,
        required: false,
        default: false,
      },
      gameAlias: {
        type: String,
        required: false,
        default: null,
      },
      showSecondButton: {
        type: Boolean,
        required: false,
        default: false,
      },
      onSecondButtonClick: {
        type: Function,
        required: false,
        default: () => {/** Nothing to do */
        },
      },
      secondButtonLabel: {
        type: String,
        required: false,
        default: '92',
      },
    },
  });

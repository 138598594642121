
  import Vue from 'vue';
  import PwaPrizesListSlider from "@/components/common/PwaPrizesListSlider.vue";

  export default Vue.extend({
    name: "pwa-prizes-distributed",
    components: {
      PwaPrizesListSlider,
    },
    props: {
      prizesDistributed: {
        type: Array,
        default: [] as any[],
        required: true,
      }
    },
  });

import Vue from 'vue';
import {
  ButtonPlugin,
  ButtonGroupPlugin,
  CarouselPlugin,
  CollapsePlugin,
  DropdownPlugin,
  FormPlugin,
  FormCheckboxPlugin,
  FormFilePlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormRadioPlugin,
  FormSelectPlugin,
  FormTextareaPlugin,
  InputGroupPlugin,
  LayoutPlugin,
  ModalPlugin,
  NavPlugin,
  NavbarPlugin,
  SidebarPlugin,
  TablePlugin,
  TabsPlugin,
  ToastPlugin,
  TooltipPlugin,
  VBModalPlugin,
  VBTogglePlugin,
  VBTooltipPlugin,
} from 'bootstrap-vue';

Vue.use(ButtonPlugin, {BButton: {variant: 'primary'}});
Vue.use(ButtonGroupPlugin);
Vue.use(CarouselPlugin);
Vue.use(CollapsePlugin);
Vue.use(DropdownPlugin);
Vue.use(FormPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormFilePlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormRadioPlugin);
Vue.use(FormSelectPlugin);
Vue.use(FormTextareaPlugin);
Vue.use(InputGroupPlugin);
Vue.use(LayoutPlugin);
Vue.use(ModalPlugin, {BModal: {centered: true}});
Vue.use(NavPlugin);
Vue.use(NavbarPlugin);
Vue.use(SidebarPlugin);
Vue.use(TablePlugin);
Vue.use(TabsPlugin);
Vue.use(ToastPlugin);
Vue.use(TooltipPlugin);
Vue.use(VBModalPlugin);
Vue.use(VBTogglePlugin);
Vue.use(VBTooltipPlugin);

/* eslint-disable no-console */

import { register } from 'register-service-worker';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}firebase-messaging-sw.js`, {
    ready() {
      /** Nothing to do */
    },
    registered() {
      /** Nothing to do */
    },
    cached() {
      /** Nothing to do */
    },
    updatefound() {
      /** Nothing to do */
    },
    updated(registration) {
      registration.waiting.postMessage({type: 'SKIP_WAITING'});
      registration.update();
    },
    offline() {
      /** Nothing to do */
    },
    error() {
      /** Nothing to do */
    }
  })
}


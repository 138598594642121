import api, { ApiCallReturn } from "../api";
import { SocialLoginType } from '@/utils/social-login';

export const postLogin = (data: {email: string, password: string, referer?: string;}|{social: SocialLoginType, referer?: string;}): ApiCallReturn => api.post('/auth/login', data);
export const postVerifyAccount = (token: string, userId: string): ApiCallReturn => api.post(`/auth/verify/${token}/${userId}`);
export const postSignUp = (data: any = {}): ApiCallReturn => api.post('/auth/signup', data);
export const postCheckField = (data: Record<string, string>): ApiCallReturn => api.post('/auth/checkfield', data);
export const postRecoverAccount = (email: string): ApiCallReturn => api.post('/auth/recover-account', {email});
export const postRecoverAccountUpdateInfo = (data: Record<string, any>): ApiCallReturn => api.post('/auth/recover-account/update-info', data);
export const postDeviceToken = (userId: number, token: string): ApiCallReturn => api.post(`/push-notifications/${userId}/device_token`, {token});
export const deleteDeviceToken = (userId: number, token: string): ApiCallReturn => api.delete(`/push-notifications/${userId}/device_token`, {params: {token}});
export const postContact = (data: Record<string, any>): ApiCallReturn => api.post(`/auth/send-email`, data);

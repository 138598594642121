
  import Vue from "vue";

  export default Vue.extend({
    name: "pwa-carousel",
    props: {
      banners: {
        type: Array,
        required: true,
      }
    },
    methods: {
      async onClickBanner(banner: any) {
        if (banner.url) {
          window.open(banner.url, '_blank');
        }
      }
    }
  });

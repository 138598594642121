
  import Vue, {PropType} from "vue";
  import Swiper from 'swiper';
  import { FreeMode, Autoplay, Navigation } from 'swiper/modules';
  import {SwiperOptions} from "swiper/types/swiper-options";
  import PwaIcon from "@/components/common/PwaIcon.vue";

  const defaultOptions: SwiperOptions = {
    slidesPerView: 'auto',
    freeMode: {
      enabled: true,
      sticky: false,
    },
    spaceBetween: 16,
  };

  export default Vue.extend({
    name: "pwa-slider",
    components: {
      PwaIcon,
    },
    props: {
      autoplayDelay: {
        type: Number,
        required: false,
        default: 0,
      },
      showNavigation: {
        type: Boolean,
        required: false,
        default: false,
      },
      fitContent: {
        type: Boolean,
        required: false,
        default: true,
      },
      options: {
        type: Object as PropType<SwiperOptions>,
        required: false,
        default: () => ({}),
      },
    },
    data() {
      return {} as { swiper?: Swiper };
    },
    mounted(): void {
      const modules = [];
      const options: SwiperOptions  = {...defaultOptions, ...this.options};
      if (!options.autoplay && this.autoplayDelay) {
        options.autoplay = {
          delay: this.autoplayDelay,
          disableOnInteraction: true,
          pauseOnMouseEnter: true,
        };
      }

      if (!options.navigation && this.showNavigation) {
        options.navigation = {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        };
      }

      if (options.freeMode) {
        modules.push(FreeMode);
      }

      if (options.autoplay) {
        modules.push(Autoplay);
      }

      if (options.navigation) {
        modules.push(Navigation);
      }

      options.modules = modules;

      const sliderContainer = this.$refs.slider as HTMLElement;
      this.swiper = new Swiper(sliderContainer, options);
    },
  });

import { ActionTree } from 'vuex';
import { State } from "@/store/types";
import { UserSourceMutationTypes } from './mutations';
import { UserSourceState } from './state';

export const UserSourceActionTypes = {
  UPDATE_USER_SOURCE: '@userSource/UPDATE_USER_SOURCE',
};

const actions: ActionTree<UserSourceState, State> = {
  [UserSourceActionTypes.UPDATE_USER_SOURCE]({commit}, {source, medium, campaign, content}) {
    return new Promise(resolve => {
      commit(UserSourceMutationTypes.SET_USER_SOURCE, {source, medium, campaign, content});
      resolve(true);
    });
  },
};

export default actions;

import { Middleware } from "@/router/types";
import { ROUTES } from "@/router/routes";

export const anonymous: Middleware = ({next, store, to}) => {
  if (store.getters.isLoggedIn) {
    next({name: ROUTES.lobby.name, query: to.query, params: to.params});
  } else {
    next();
  }
};

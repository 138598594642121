import moment from 'moment-timezone';

export const TIMEZONE_MADRID = 'Europe/Madrid';
export const SERVER_TIMEZONE = TIMEZONE_MADRID;
export const SERVER_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const USER_DATETIME_FORMAT = 'L LT';
export const USER_DATE_FORMAT = 'L';
export const TIME_FORMAT = 'HH:mm[h]';

export const getUserTimezone = (): string => moment().tz();

export const getDate = (date: string, dateFormat = SERVER_DATE_FORMAT, dateTimezone = SERVER_TIMEZONE): moment.Moment => {
  return moment.tz(date, dateFormat, dateTimezone);
};

export const getUserDate = (date: string, dateFormat = SERVER_DATE_FORMAT, dateTimezone = SERVER_TIMEZONE): moment.Moment => {
  const userTimezone = getUserTimezone();
  const _date = getDate(date, dateFormat, dateTimezone);
  return _date.tz(userTimezone);
};

export const formatUserDateTime = (date: string, dateFormat = SERVER_DATE_FORMAT, dateTimezone = SERVER_TIMEZONE): string => {
  const userDate = getUserDate(date, dateFormat, dateTimezone);
  return formatDate(userDate);
};

export const formatUserDate = (date: string, dateFormat = SERVER_DATE_FORMAT, dateTimezone = SERVER_TIMEZONE): string => {
  const userDate = getUserDate(date, dateFormat, dateTimezone);
  return formatDate(userDate, USER_DATE_FORMAT);
};


export const formatUserTime = (date: string, dateFormat = SERVER_DATE_FORMAT, dateTimezone = SERVER_TIMEZONE): string => {
  const userDate = getUserDate(date, dateFormat, dateTimezone);
  return formatDate(userDate, TIME_FORMAT);
};

export const formatDate = (date: moment.Moment, userDateFormat = USER_DATETIME_FORMAT): string => date.format(userDateFormat);

import { ActionTree } from 'vuex';
import { State } from "@/store/types";
import { ModalsSeenMutationTypes } from './mutations';
import { ModalsSeenState } from './state';
import moment from 'moment-timezone';
import { MODAL_IDS } from '@/utils/modal';

export const ModalsSeenActionTypes = {
  UPDATE_MODAL_LAST_SEEN: '@modalsSeen/UPDATE_MODAL_LAST_SEEN',
};

export const MODAL_NAMES = {
  INSTALL_PWA: MODAL_IDS.INSTALL_PWA,
  DEPOSIT_REMINDER: MODAL_IDS.DEPOSIT_REMINDER,
};

const actions: ActionTree<ModalsSeenState, State> = {
  [ModalsSeenActionTypes.UPDATE_MODAL_LAST_SEEN]({commit}, modalName) {
    return new Promise(resolve => {
      commit(ModalsSeenMutationTypes.SET_MODAL_LAST_SEEN, {modalName, lastSeen: moment.utc().toISOString()});
      resolve(true);
    });
  },
};

export default actions;

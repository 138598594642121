
  import Vue, {PropType} from "vue";
  import moment from 'moment-timezone';
  import {getDate, SERVER_DATE_FORMAT, SERVER_TIMEZONE} from "@/utils/date";

  const noTimeValue = '00h 00m 00s';

  export default Vue.extend({
    name: "pwa-countdown-message",
    data() {
      return {
        countdown: noTimeValue,
        intervalObject: null,
        extraClass: '',
      };
    },
    props: {
      date: {
        type: [String, Object] as PropType<string|moment.Moment>,
        required: true,
      },
      messageId: {
        type: String,
        default: null,
      },
      messageReplaceValue: {
        type: String,
        default: 'countDown',
      },
      dateFormat: {
        type: String,
        default: SERVER_DATE_FORMAT,
      },
      dateTimezone: {
        type: String,
        default: SERVER_TIMEZONE,
      },
      refreshPeriod: {
        type: Number,
        default: 1000,
      },
      warningTime: {
        type: Number,
        default: 500, // seconds
      },
      dangerTime: {
        type: Number,
        default: 200, // seconds
      },
      onCountDownFinished: {
        type: Function as PropType<() => void>,
        default: null,
      },
    },
    mounted(): void {
      this.initInterval();
    },
    beforeDestroy(): void {
      this.clearInterval();
    },
    computed: {
      countdownMessage(): string {
        return this.messageId
          ? this.$t(this.messageId, {[this.messageReplaceValue]: this.countdown})
          : this.countdown;
      },
    },
    methods: {
      clearInterval(): void {
        if (this.intervalObject) {
          clearInterval(this.intervalObject);
          this.intervalObject = null;
        }
      },
      initInterval(): void {
        if (this.date) {
          this.clearInterval();
          const utcDate = typeof this.date === 'string'
                  ? getDate(this.date, this.dateFormat, this.dateTimezone).utc()
                  : this.date.clone().utc();
          this.intervalObject = setInterval(() => {
            const diff = moment.duration(utcDate.diff(moment.utc(), 'millisecond', true));
            const seconds = diff.asSeconds();

            if (seconds > 0) {
              let daysString = '';
              const days = diff.days();
              if (days > 0) {
                daysString = `${days.toString()}d `;
                // if (days < 10) {
                //   daysString = '0' + daysString;
                // }
              }
              const hoursString = diff.hours() < 10 ? `0${diff.hours().toString()}` : diff.hours().toString();
              const minutesString = diff.minutes() < 10 ? `0${diff.minutes().toString()}` : diff.minutes().toString();
              const secondsString = diff.seconds() < 10 ? `0${diff.seconds().toString()}` : diff.seconds().toString();
              this.countdown = `${daysString}${hoursString}:${minutesString}:${secondsString}`;

              if (seconds < this.dangerTime) {
                this.extraClass = 'danger';
              } else if (seconds < this.warningTime) {
                this.extraClass = 'warning';
              } else {
                this.extraClass = 'info';
              }

            } else {
              this.countdown = noTimeValue;
              this.extraClass = 'no-time';
              this.clearInterval();
              if (this.onCountDownFinished) {
                this.onCountDownFinished();
              }
            }

          }, this.refreshPeriod);
        }
      },
    },
    watch: {
      date() {
        this.initInterval();
      },
    },
  });

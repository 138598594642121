import { MutationTree } from 'vuex/types';
import { AppState } from './state';

export const AppMutationTypes = {
  SET_LOADING: '@app/SET_LOADING',
  SET_THEME: '@app/SET_THEME',
  SET_CONFIG: '@app/SET_CONFIG',
  SET_DEVICE: '@app/SET_DEVICE',
  SET_MODAL_PROPS: '@app/SET_MODAL_PROPS',
  SET_NEXT_URL: '@app/SET_NEXT_URL',
  SET_PAGE_DATA: '@app/SET_PAGE_DATA',
  SET_BREADCRUMB: '@app/SET_BREADCRUMB',
  SET_IS_PWA_INSTALLED: '@app/SET_IS_PWA_INSTALLED',
  SET_GAME: '@app/SET_GAME',
  SET_TOP_MODAL_HEIGHT: '@app/SET_TOP_MODAL_HEIGHT',
  SET_ADS_LOADED: '@app/SET_ADS_LOADED',
  SET_SHOW_ADS: '@app/SET_SHOW_ADS',
};

const mutations: MutationTree<AppState> = {
  [AppMutationTypes.SET_LOADING](app: AppState, loading: boolean) {
    app.loading = loading;
  },
  [AppMutationTypes.SET_CONFIG](app: AppState, config) {
    app.config = config;
  },
  [AppMutationTypes.SET_THEME](app: AppState, theme) {
    app.theme = theme;
  },
  [AppMutationTypes.SET_DEVICE](app: AppState, device) {
    app.device = device;
  },
  [AppMutationTypes.SET_MODAL_PROPS](app: AppState, modalProps) {
    app.modalProps = modalProps;
  },
  [AppMutationTypes.SET_NEXT_URL](app: AppState, nextUrl) {
    app.nextUrl = nextUrl;
  },
  [AppMutationTypes.SET_PAGE_DATA](app: AppState, pageData) {
    app.pageData = pageData;
  },
  [AppMutationTypes.SET_BREADCRUMB](app: AppState, breadcrumb) {
    app.breadcrumb = breadcrumb;
  },
  [AppMutationTypes.SET_IS_PWA_INSTALLED](app: AppState, isPwaInstalled) {
    app.isPwaInstalled = isPwaInstalled;
  },
  [AppMutationTypes.SET_GAME](app: AppState, game) {
    app.game = game;
  },
  [AppMutationTypes.SET_TOP_MODAL_HEIGHT](app: AppState, topModalHeight) {
    app.topModalHeight = topModalHeight;
  },
  [AppMutationTypes.SET_ADS_LOADED](app: AppState, adsLoaded) {
    app.adsLoaded = adsLoaded;
  },
  [AppMutationTypes.SET_SHOW_ADS](app: AppState, showAds) {
    app.showAds = showAds;
  },
};

export default mutations;

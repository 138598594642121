
  import Vue from "vue";

  export default Vue.extend({
    name: "pwa-minigames-videos",
    props: {
      showVideoBackground: {
        type: Boolean,
        default: true,
        required: false,
      },
    },
  });

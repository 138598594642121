
  import Vue from "vue";
  import {ROUTES} from "@/router/routes";
  import {COMPETITION_STATUS_ID} from "@/utils/competition";
  import PwaIcon from "@/components/common/PwaIcon.vue";
  import PwaPrice from "@/components/common/PwaPrice.vue";
  import PwaShortAvatarsList from "@/components/competitions/PwaShortAvatarsList.vue";

  export default Vue.extend({
    name: "pwa-multiplayer-card",
    components: {
      PwaIcon,
      PwaPrice,
      PwaShortAvatarsList,
    },
    props: {
      competition: Object,
    },
    data() {
      return {
        routes: ROUTES,
      }
    },
    computed: {
      availablePlaces(): number {
        return this.competition.max_players - this.competition.occupation;
      },
      usersList(): any[] {
        return this.competition?.players || [];
      },
      isActive(): boolean {
        return this.competition.status_id === COMPETITION_STATUS_ID.STARTED || this.competition.status_id === COMPETITION_STATUS_ID.PENDING;
      }
    },
    methods: {
      onMultiplayerCardClick(): void {
        this.routerPush(ROUTES.multiplayer.name , {competitionId: this.competition.id});
      },
    }
  });

import axios, { AxiosResponse } from 'axios';
import { VueRouter } from "vue-router/types/router";
import { AuthActionTypes } from "@/store/modules/auth/actions";
import { Store } from "vuex";
import { State } from "@/store/types";
import { ROUTES } from "@/router/routes";
import { isNativeApp, platform } from '@/utils/native-app';

const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    'App-Secret': process.env.VUE_APP_API_APP_SECRET,
    'App-Platform': isNativeApp ? platform : 'web',
  }
});

const setHeader = (name: string, value: string): void => {
  api.defaults.headers[name] = value;
};

export const setAuthToken = (token: string | null): void => {
  if (token) {
    setHeader('Authorization', `Bearer ${token}`);
  } else {
    removeAuthToken();
  }
};

export const removeAuthToken = (): void => {
  delete api.defaults?.headers?.Authorization;
};

export const setApiLang = (lang: string): void => {
  setHeader('App-Lang', lang);
};

export const setAppPlatformHeader = (platform: string): void => {
  setHeader('App-Platform', platform);
};

export const initApiInterceptors = (router: VueRouter, store: Store<State>): void => {
  /**
   * Control all requests
   */
  api.interceptors.response.use(config => config, error => {
    const status = error?.response?.data?.status;
    if (status === 403) {
      store.dispatch(AuthActionTypes.LOGOUT);
    } else if (status === 404) {
      router.replace({name: ROUTES.error.name, params: {lang: router.currentRoute.params.lang}});
    }
    return Promise.reject(error);
  });
};

type ApiReturnData = {
  data?: any,
};

export type ApiReturn = AxiosResponse<ApiReturnData>;
export type ApiCallReturn = Promise<ApiReturn>;

export default api;

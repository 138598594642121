import { store } from '@/store';
import { SocialLoginActionTypes } from '@/store/modules/socialLogin/actions';
import { SOCIAL_LOGIN_PLATFORMS } from '@/utils/social-login';

export const initGoogleLogin = (onLoginCallback: (credential: string) => void): void => {
  if (process.env.VUE_APP_GOOGLE_CLIENT_ID && store.state.socialLogin.google.initialized !== true) {
    if (window.google) {
      google.accounts.id.initialize({
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        callback: response => onLoginCallback(response.credential),
      });

      store.dispatch(SocialLoginActionTypes.SET_PLATFORM_INITIALIZED, { platform: SOCIAL_LOGIN_PLATFORMS.GOOGLE, initialized: true })
    } else {
      window.onGoogleLibraryLoad = () => {
        initGoogleLogin(onLoginCallback);
      };
    }
  }
};

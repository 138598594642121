import { ActionTree } from 'vuex';
import { State } from "@/store/types";
import { UserMutationTypes } from './mutations';
import { UserState } from './state';
import { app } from '@/main';

export const UserActionTypes = {
  UPDATE_USER_WALLET: '@user/UPDATE_USER_WALLET',
  UPDATE_USER_GAMES: '@user/UPDATE_USER_GAMES',
  UPDATE_MINIGAMES: '@user/UPDATE_MINIGAMES',
  UPDATE_USER_CHAT_LAST_MESSAGES: '@user/UPDATE_USER_CHAT_LAST_MESSAGES',
  UPDATE_USER_CHAT_HAS_UNREAD_LAST_MESSAGES: '@user/UPDATE_USER_CHAT_HAS_UNREAD_LAST_MESSAGES',
  UPDATE_USER_CHAT_COMPETITIONS_LAST_MESSAGES: '@user/UPDATE_USER_CHAT_COMPETITIONS_LAST_MESSAGES',
  UPDATE_USER_CHAT_HAS_UNREAD_COMPETITIONS_LAST_MESSAGES: '@user/UPDATE_USER_CHAT_HAS_UNREAD_COMPETITIONS_LAST_MESSAGES',
  UPDATE_CHAT_CONNECTED: '@user/UPDATE_CHAT_CONNECTED',
  SET_USER_WALLET_QUANTITY: '@user/SET_USER_WALLET_QUANTITY',
};

const actions: ActionTree<UserState, State> = {
  [UserActionTypes.UPDATE_USER_WALLET]({commit, rootGetters}) {
    return new Promise((resolve, reject) => {
      const userId = rootGetters.userId;
      app.$http.user.getUserWallet(userId).then(({data}) => {
        const wallet = data.data;
        commit(UserMutationTypes.SET_USER_WALLET, wallet);
        resolve(true);
      }).catch(() => {
        reject();
      });
    });
  },
  [UserActionTypes.UPDATE_USER_GAMES]({commit, rootGetters}) {
    return new Promise((resolve, reject) => {
      const userId = rootGetters.userId;
      app.$http.user.getUserGames(userId).then(({data}) => {
        const games = data.data;
        commit(UserMutationTypes.SET_USER_GAMES, games);
        resolve(true);
      }).catch(() => {
        reject();
      });
    });
  },
  [UserActionTypes.UPDATE_MINIGAMES]({commit}) {
    return new Promise((resolve, reject) => {
      app.$http.game.getMinigames().then(({data}) => {
        const minigames = data.data;
        commit(UserMutationTypes.SET_MINIGAMES, minigames);
        resolve(true);
      }).catch(() => {
        reject();
      });
    });
  },
  [UserActionTypes.UPDATE_USER_CHAT_LAST_MESSAGES]({commit}, lastMessages) {
    return new Promise(resolve => {
      commit(UserMutationTypes.SET_USER_CHAT_LAST_MESSAGES, lastMessages);
      resolve(true);
    });
  },
  [UserActionTypes.UPDATE_USER_CHAT_HAS_UNREAD_LAST_MESSAGES]({commit}, hasLastMessagesUnreadMessages) {
    return new Promise(resolve => {
      commit(UserMutationTypes.SET_USER_CHAT_HAS_UNREAD_LAST_MESSAGES, hasLastMessagesUnreadMessages);
      resolve(true);
    });
  },
  [UserActionTypes.UPDATE_USER_CHAT_COMPETITIONS_LAST_MESSAGES]({commit}, competitionsLastMessages) {
    return new Promise(resolve => {
      commit(UserMutationTypes.SET_USER_CHAT_COMPETITIONS_LAST_MESSAGES, competitionsLastMessages);
      resolve(true);
    });
  },
  [UserActionTypes.UPDATE_USER_CHAT_HAS_UNREAD_COMPETITIONS_LAST_MESSAGES]({commit}, hasCompetitionsLastMessagesUnreadMessages) {
    return new Promise(resolve => {
      commit(UserMutationTypes.SET_USER_CHAT_HAS_UNREAD_COMPETITIONS_LAST_MESSAGES, hasCompetitionsLastMessagesUnreadMessages);
      resolve(true);
    });
  },
  [UserActionTypes.UPDATE_CHAT_CONNECTED]({commit}, connected) {
    return new Promise(resolve => {
      commit(UserMutationTypes.SET_CHAT_CONNECTED, connected);
      resolve(true);
    });
  },
  [UserActionTypes.SET_USER_WALLET_QUANTITY]({commit}, quantity) {
    return new Promise(resolve => {
      commit(UserMutationTypes.SET_USER_WALLET_QUANTITY, quantity);
      resolve(true);
    });
  },
};

export default actions;

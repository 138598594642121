
  import Vue from "vue";
  import {MODAL_IDS} from "@/utils/modal";
  import {ROUTES} from "@/router/routes";

  export default Vue.extend({
    name: "pwa-deposit-banner",
    props: {
      text: {
        type: String,
        required: false,
      },
      buttonText: {
        type: String,
        required: false,
      },
      buttonClass: {
        type: String,
        required: false,
      },
      urlPath: {
        type: String,
        required: false,
      }
    },
    methods: {
      async onClickDeposit() {
        if(this.urlPath){
          await this.$router.push((this.routeLang ? ('/' + this.routeLang) : '') + this.urlPath);
        }else{
          if (this.isLoggedIn) {
            if (this.isDesktop) {
              this.showModalById(MODAL_IDS.DEPOSIT);
            } else {
              await this.$router.push({name: ROUTES.deposit.name, params: {lang: this.routeLang}});
            }
          } else {
            this.goToSignup();
          }
        }
      },
      getButtonText() {
        let text = '';

        if(this.buttonText) {
          text = this.buttonText;
        }else{
          text = this.isLoggedIn ? this.$t('171') : this.$t('28');
        }

        return text;
      },
      getButtonClass() {
        let btnClass = '';

        if(this.buttonClass) {
          if(this.isMobile){
            btnClass = this.buttonClass;
          }
        }else{
          if(this.isMobile){
            btnClass = 'cta-principal';
          }
        }

        return btnClass;
      }
    }
  });

import { MutationTree } from 'vuex/types';
import { UserState } from './state';

export const UserMutationTypes = {
  SET_USER_WALLET: '@user/SET_USER_WALLET',
  SET_USER_GAMES: '@user/SET_USER_GAMES',
  SET_MINIGAMES: '@user/SET_MINIGAMES',
  SET_USER_CHAT_LAST_MESSAGES: '@user/SET_USER_CHAT_LAST_MESSAGES',
  SET_USER_CHAT_HAS_UNREAD_LAST_MESSAGES: '@user/SET_USER_CHAT_HAS_UNREAD_LAST_MESSAGES',
  SET_USER_CHAT_COMPETITIONS_LAST_MESSAGES: '@user/SET_USER_CHAT_COMPETITIONS_LAST_MESSAGES',
  SET_USER_CHAT_HAS_UNREAD_COMPETITIONS_LAST_MESSAGES: '@user/SET_USER_CHAT_HAS_UNREAD_COMPETITIONS_LAST_MESSAGES',
  SET_CHAT_CONNECTED: '@user/SET_CHAT_CONNECTED',
  SET_USER_WALLET_QUANTITY: '@user/SET_USER_WALLET_QUANTITY',
};

const mutations: MutationTree<UserState> = {
  [UserMutationTypes.SET_USER_WALLET](user: UserState, wallet) {
    user.wallet = wallet;
  },
  [UserMutationTypes.SET_USER_GAMES](user: UserState, games) {
    user.games = games;
  },
  [UserMutationTypes.SET_MINIGAMES](user: UserState, minigames) {
    user.minigames = minigames;
  },
  [UserMutationTypes.SET_USER_CHAT_LAST_MESSAGES](user: UserState, lastMessages) {
    user.chat.lastMessages = lastMessages;
  },
  [UserMutationTypes.SET_USER_CHAT_HAS_UNREAD_LAST_MESSAGES](user: UserState, hasLastMessagesUnreadMessages) {
    user.chat.hasLastMessagesUnreadMessages = hasLastMessagesUnreadMessages;
  },
  [UserMutationTypes.SET_USER_CHAT_COMPETITIONS_LAST_MESSAGES](user: UserState, competitionsLastMessages) {
    user.chat.competitionsLastMessages = competitionsLastMessages;
  },
  [UserMutationTypes.SET_USER_CHAT_HAS_UNREAD_COMPETITIONS_LAST_MESSAGES](user: UserState, hasCompetitionsLastMessagesUnreadMessages) {
    user.chat.hasCompetitionsLastMessagesUnreadMessages = hasCompetitionsLastMessagesUnreadMessages;
  },
  [UserMutationTypes.SET_CHAT_CONNECTED](user: UserState, connected) {
    user.chat.connected = connected;
  },
  [UserMutationTypes.SET_USER_WALLET_QUANTITY](user: UserState, quantity) {
    user.wallet.quantity = quantity;
  },
};

export default mutations;

import { MutationTree } from 'vuex/types';
import { AuthState } from './state';

export const AuthMutationTypes = {
  SET_ACCEPT_COOKIES: '@auth/ACCEPT_COOKIES',
  SET_FRIEND_CODE: '@auth/SET_FRIEND_CODE',
  SET_USER: '@auth/SET_USER',
  SET_TOKEN: '@auth/SET_TOKEN',
  SET_FIREBASE_TOKEN: '@auth/SET_FIREBASE_TOKEN',
  SET_VIDEO_INTERSTITIAL_SEEN: '@auth/SET_VIDEO_INTERSTITIAL_SEEN',
};

const mutations: MutationTree<AuthState> = {
  [AuthMutationTypes.SET_ACCEPT_COOKIES](auth: AuthState, acceptCookies) {
    auth.acceptCookies = acceptCookies;
  },
  [AuthMutationTypes.SET_FRIEND_CODE](auth: AuthState, friendCode) {
    auth.friendCode = friendCode;
  },
  [AuthMutationTypes.SET_USER](auth: AuthState, user) {
    auth.user = user;
  },
  [AuthMutationTypes.SET_TOKEN](auth: AuthState, token) {
    auth.token = token;
  },
  [AuthMutationTypes.SET_FIREBASE_TOKEN](auth: AuthState, token) {
    auth.firebaseToken = token;
  },
  [AuthMutationTypes.SET_VIDEO_INTERSTITIAL_SEEN](auth: AuthState, videoInterstitialSeen) {
    auth.videoInterstitialSeen = videoInterstitialSeen;
  },
};

export default mutations;

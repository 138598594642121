// eslint-disable-next-line @typescript-eslint/no-var-requires
const PATHS = require('./paths.js');

export const ROUTES = {
  home: {path: PATHS.home.path, name: 'Home'},
  aboutUs: {path: PATHS.aboutUs.path, name: 'AboutUs'},
  help: {path: PATHS.help.path, name: 'Help'},
  support: {path: PATHS.support.path, name: 'Support'},
  lobby: {path: PATHS.lobby.path, name: 'Lobby'},
  arena: {path: PATHS.arena.path, name: 'Arena'},
  minigame: {path: PATHS.minigame.path, name: 'Minigame'},
  multiplayer: {path: PATHS.multiplayer.path, name: 'Multiplayer'},
  wallet: {path: PATHS.wallet.path, name: 'Wallet'},
  walletSuccess: {path: PATHS.walletSuccess.path, name: 'walletSuccess'},
  deposit: {path: PATHS.deposit.path, name: 'Deposit'},
  withdraw: {path: PATHS.withdraw.path, name: 'Withdraw'},
  competition: {path: PATHS.competition.path, name: 'Competition'},
  ranked: {path: PATHS.ranked.path, name: 'Ranked'},
  competitionChat: {path: PATHS.competitionChat.path, name: 'CompetitionChat'},
  competitionMatch: {path: PATHS.competitionMatch.path, name: 'CompetitionResult'},
  competitions: {path: PATHS.competitions.path, name: 'Competitions'},
  competitionsHistory: {path: PATHS.competitionsHistory.path, name: 'CompetitionsHistory'},
  create: {path: PATHS.create.path, name: 'Create'},
  practiceGame: {path: PATHS.practiceGame.path, name: 'PracticeGame'},
  playGame: {path: PATHS.playGame.path, name: 'PlayGame'},
  profile: {path: PATHS.profile.path, name: 'Profile'},
  followers: {path: PATHS.followers.path, name: 'Followers'},
  account: {path:PATHS.account.path, name: 'Account'},
  messages: {path: PATHS.messages.path, name: 'Messages'},
  messagesUser: {path: PATHS.messagesUser.path, name: 'MessagesUser'},
  inviteFriend: {path: PATHS.inviteFriend.path, name: 'InviteFriend'},
  refer: {path: PATHS.refer.path, name: 'Refer'},
  verifyIdentity: {path: PATHS.verifyIdentity.path, name: 'VerifyIdentity'},
  notificationCenter: {path: PATHS.notificationCenter.path, name: 'NotificationCenter'},
  promo: {path: PATHS.promo.path, name: 'Promo'},
  login: {path: PATHS.login.path, name: 'Login'},
  verifyAccount: {path: PATHS.verifyAccount.path, name: 'Verify'},
  forgotPassword: {path: PATHS.forgotPassword.path, name: 'ForgotPassword'},
  signup: {path: PATHS.signup.path, name: 'SignUp'},
  terms: {path: PATHS.terms.path, name: 'Terms'},
  privacy: {path: PATHS.privacy.path, name: 'Privacy'},
  cookies: {path: PATHS.cookies.path, name: 'Cookies'},
  recoverAccount: {path: PATHS.recoverAccount.path, name: 'RecoverAccount'},
  landingWelcome: {path: PATHS.landingWelcome.path, name: 'LandingWelcome'},
  blogHome: {path: PATHS.blogHome.path, name: 'BlogHome'},
  blogPost: {path: PATHS.blogPost.path, name: 'BlogPost'},
  blogTag: {path: PATHS.blogTag.path, name: 'BlogTag'},
  roulette: {path: PATHS.roulette.path, name: 'Roulette'},
  challenges: {path: PATHS.challenges.path, name: 'Challenges'},
  error: {path: PATHS.error.path, name: 'Error'},
};

import { GetterTree } from 'vuex/types';
import { State } from "@/store/types";
import { AuthState } from "./state";

const getters: GetterTree<AuthState, State> = {
  acceptCookies: auth => Boolean(auth.acceptCookies),
  isLoggedIn: auth => Boolean(auth.token),
  userId: auth => auth.user?.id || null,
  username: auth => auth.user?.username || null,
  videoInterstitialSeen: auth => auth.videoInterstitialSeen,
};

export default getters;

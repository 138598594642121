import api, { ApiCallReturn } from '../api';

export const getMinigames = (): ApiCallReturn => api.get(`/minigames`);

export const getGamesSettings = (gameId: number): ApiCallReturn => api.get(`/games-settings/${gameId}`);

export const getGameRules = (gameId: number, competitionTypeId: number): ApiCallReturn => {
  const params: any = {
    game_id: gameId,
    type_id: competitionTypeId,
  };
  return api.get(`/competitions-rules/gfy-score`, {params});
};

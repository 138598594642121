
  import Vue, {PropType} from "vue";
  import {CurrencyType} from "@/utils/common";

  const currencyTypes = {
    auto: 'auto',
    icon: 'icon',
    symbol: 'symbol',
  };

  export default Vue.extend({
    name: "pwa-currency",
    props: {
      currencyType: {
        type: String as PropType<"auto" | "icon" | "symbol">,
        default: 'auto',
        required: false,
      },
      iconHeight: {
        type: String,
        default: '15px',
        required: false,
      },
    },
    computed: {
      currency(): CurrencyType {
        return this.$store.state.app.config.currency;
      },
      hasIcon(): boolean {
        return Boolean(this.currency.icon);
      },
      showIcon(): boolean {
        return this.hasIcon && (this.currencyType === currencyTypes.auto || this.currencyType === currencyTypes.icon);
      },
      showSymbol(): boolean {
        return !this.showIcon;
      },
    },
  });

import api, { ApiCallReturn } from "../api";

export const postCancelCompetition = (competitionId: number, userId: number): ApiCallReturn => api.post(`/users/${userId}/cancel-wager/${competitionId}`);
export const postCompetitionsFilter = (params: any = {}): ApiCallReturn => api.post(`competitions/filter`, params);
export const getCompetitionResult = (competitionId: number, matchId: number, isManual: boolean, isMinigame: boolean): ApiCallReturn => {
  const params: any = {
    is_manual: isManual ? 1 : 0,
    is_minigame: isMinigame ? 1 : 0,
  };
  return api.get(`/wagers-results/${competitionId}/match/${matchId}`, {params});
};
export const postCompetitionManualResult = (competitionId: number, resultId: number, data: Record<string, any>): ApiCallReturn =>
  api.post(`/competitions-results/${competitionId}/manual/${resultId}`, data);
export const postPracticeMinigame = (data: Record<string, any>): ApiCallReturn =>
  api.post(`/minigames/practice`, data);
export const postMinigameResult = (competitionId: number, resultId: number, data: Record<string, any>): ApiCallReturn =>
  api.post(`/minigames-results/${competitionId}/match/${resultId}`, data);
export const getCreateMatchPage = (): ApiCallReturn =>
  api.get('/web/pages/create-match');
export const postCheckCreateMatch = (userId: number, data: Record<string, string|number>): ApiCallReturn =>
  api.post(`/users/${userId}/check-create-wager`, data);
export const postCreateMatch = (userId: number, data: Record<string, string|number>): ApiCallReturn =>
  api.post(`/users/${userId}/create-wager`, data);
export const getCompetitionMatchTicket = (competitionId: number, matchId: number): ApiCallReturn => api.get(`/competitions/${competitionId}/matches/${matchId}/ticket`);
export const postCompetitionTicket = (competitionId: number, matchId: number): ApiCallReturn => api.post(`/competitions/${competitionId}/ticket`, {match_id: matchId});
export const postCompetitionProposal = (data: Record<string, string|number>): ApiCallReturn => api.post('/competitions_proposals', data);
export const postCompetitionProposalResponse = (id: number, data: Record<string, string|number>): ApiCallReturn => api.post(`/competitions_proposals/${id}/response`, data);
export const postCompetitionProposalRejectAll = (): ApiCallReturn => api.post('/competitions_proposals/reject-all');
export const postCompetitionProposalCancel = (id: number): ApiCallReturn => api.post(`/competitions_proposals/${id}/cancel`);
export const getCompetitionProposal = (params: Record<string, string|number>): ApiCallReturn => api.get('/competitions_proposals', {params});

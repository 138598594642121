import { Middleware } from "@/router/types";
import { ROUTES } from "@/router/routes";

export const allowDeposit: Middleware = ({to, next, store}) => {
  if (to.name === ROUTES.deposit.name) {
    if (store.getters.allowDeposit) {
      next();
    } else {
      next({name: ROUTES.wallet.name, query: to.query, params: to.params});
    }
  }
};

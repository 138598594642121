import Vue from 'vue';
import { AppActionTypes } from "@/store/modules/app/actions";
import { BreadcrumbItemType } from "@/utils/breadcrumb";
import { MetaInfo } from 'vue-meta';
import { APP_LANGS, LANGS } from '@/utils/i18n';
import { LinkPropertyBase, LinkPropertyHref, LinkPropertyHrefCallback } from 'vue-meta/types/vue-meta';
import { pwaBaseUrl } from '@/utils/common';

export default Vue.extend({
  mounted(): void {
    if (this.breadcrumb && this.isDesktop) {
      this.setBreadcrumb(this.breadcrumb);
    }
  },
  metaInfo() {
    const metaData = this.metaData || {};
    return {
      htmlAttrs: {
        lang: this.currentLang,
      },
      meta: [
        {name: 'description', content: this.$t('421')},
      ],
      titleTemplate: (titleChunk) => {
        return titleChunk ? `${this.appName} | ${titleChunk}` : this.appName;
      },
      title: this.$t('420'),
      ...metaData,
    };
  },
  methods: {
    setBreadcrumb(breadcrumb: BreadcrumbItemType[]) {
      this.$store.dispatch(AppActionTypes.SET_BREADCRUMB, breadcrumb);
    },
    getMetaDataPathLinks(path: string): (LinkPropertyBase | LinkPropertyHref | LinkPropertyHrefCallback)[] {
      return [
        ...APP_LANGS.map(lang => ({rel: 'alternate', hreflang: lang, href: `${pwaBaseUrl}`+ (lang != LANGS.ES ? `/${lang}` : '') + `/${path}`})),
        {rel: 'alternate', hreflang: 'x-default', href: `${pwaBaseUrl}/${path}`},
        {rel: 'canonical', href: `${pwaBaseUrl}` + (this.routeLang ? `/${this.routeLang}` : '') + `/${path}`},
      ];
    },
    savePageInitialData(data: any) {
      window.__PAGE_INITIAL_DATA = data;
    },
    getPageInitialData(keyName: string|null, defaultValue: any = null) {
      const pageInitialData = window.__PAGE_INITIAL_DATA;
      let value = defaultValue;
      if (keyName) {
        const pageData = pageInitialData || {};
        if (pageData[keyName]  !== undefined) {
          value = pageData[keyName];
        }
      } else if (pageInitialData !== undefined) {
        value = pageInitialData;
      }

      return value;
    },
    removePageInitialData() {
      if (window.__PAGE_INITIAL_DATA) {
        delete window.__PAGE_INITIAL_DATA;
      }
    },
  },
  watch: {
    breadcrumb() {
      if (this.isDesktop) {
        this.setBreadcrumb(this.breadcrumb || []);
      }
    },
  }
});

declare module 'vue/types/vue' {
  interface Vue {
    breadcrumb?: BreadcrumbItemType[] | null;
    setBreadcrumb: (breadcrumb: BreadcrumbItemType[]) => void;
    metaData: MetaInfo;
    getMetaDataPathLinks: (path: string) => (LinkPropertyBase | LinkPropertyHref | LinkPropertyHrefCallback)[];
    savePageInitialData: (data: any) => void;
    getPageInitialData: (keyName: string|null, defaultValue?: any) => any;
    removePageInitialData: () => any;
  }
}

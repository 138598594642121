import { MutationTree } from 'vuex/types';
import { SocialLoginState } from './state';

export const SocialLoginMutationTypes = {
  SET_PLATFORM_INITIALIZED: '@socialLogin/SET_PLATFORM_INITIALIZED',
};

const mutations: MutationTree<SocialLoginState> = {
  [SocialLoginMutationTypes.SET_PLATFORM_INITIALIZED](socialLogin: any, {platform, initialized}) {
    socialLogin[platform].initialized = initialized;
  },
};

export default mutations;

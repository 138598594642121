export const LANGS = {
  ES: "es",
  EN: "en",
};

export const APP_LANGS: string[] = Object.values(LANGS);

export const getRouteLang: (currentLang: string) => string|null = (currentLang: string) => currentLang === LANGS.ES ? null : currentLang;

export const botRegex = /bot|google|baidu|bing|msn|teoma|slurp|yandex/i;

export const isBot = typeof navigator !== 'undefined' ? botRegex.test(navigator.userAgent) : false;

import { GetterTree } from 'vuex/types';
import { State } from "@/store/types";
import { UserState } from "./state";

const getters: GetterTree<UserState, State> = {
  hasGames: user => (user.games.length > 0),
  hasUnreadMessages: user => (user.chat.hasLastMessagesUnreadMessages || user.chat.hasCompetitionsLastMessagesUnreadMessages),
};

export default getters;

import { ROUTES } from '@/router/routes';

export const HIDE_NAVIGATION_PAGES: Record<string, boolean> = {
  [ROUTES.landingWelcome.name]: true,
};

export const HIDE_TAB_BAR_PAGES: Record<string, boolean> = {
  [ROUTES.practiceGame.name]: true,
  [ROUTES.playGame.name]: true,
  [ROUTES.deposit.name]: true,
};

export const HIDE_GROUP_LOGO_PAGES: Record<string, boolean> = {
  [ROUTES.landingWelcome.name]: true,
};

import { ActionTree } from 'vuex';
import { State } from "@/store/types";
import { SocialLoginMutationTypes } from './mutations';
import { SocialLoginState } from './state';

export const SocialLoginActionTypes = {
  SET_PLATFORM_INITIALIZED: '@socialLogin/SET_PLATFORM_INITIALIZED',
};

const actions: ActionTree<SocialLoginState, State> = {
  [SocialLoginActionTypes.SET_PLATFORM_INITIALIZED]({commit}, {platform, initialized}) {
    return new Promise(resolve => {
      commit(SocialLoginMutationTypes.SET_PLATFORM_INITIALIZED, {platform, initialized});
      resolve(true);
    });
  },
};

export default actions;


  import Vue from "vue";
  import PwaPlatformAdvantages from '@/components/PwaPlatformAdvantages.vue';
  import PwaDepositOffer from "@/components/PwaDepositOffer.vue";
  import PwaPrizesDistributed from '@/components/PwaPrizesDistributed.vue';
  import PwaMinigamesVideos from "@/components/PwaMinigamesVideos.vue";
  //import PwaVideoYoutube from "@/components/PwaVideoYoutube.vue";
  import PwaCompetitionCard from "@/components/competitions/PwaCompetitionCard.vue";
  import PwaIcon from "@/components/common/PwaIcon.vue";
  import {isPrerender} from "@/utils/common";
  import {MODAL_IDS} from "@/utils/modal";
  import {ROUTES} from "@/router/routes";
  import pageMixin from "@/mixins/page";
  import { MetaInfo } from 'vue-meta';
  import { HIDE_GROUP_LOGO_PAGES } from '@/utils/page';

  export default Vue.extend({
    name: 'Home',
    mixins: [pageMixin],
    components: {
      PwaPlatformAdvantages,
      PwaDepositOffer,
      PwaPrizesDistributed,
      PwaMinigamesVideos,
      // PwaVideoYoutube,
      PwaCompetitionCard,
      PwaIcon,
      PwaSlider: () => import('@/components/common/PwaSlider.vue'),
      PwaDownloadAppBanner: () => import('@/components/PwaDownloadAppBanner.vue'),
      PwaFooter: () => import('@/components/layout/PwaFooter.vue'),
    },
    props: {
      referUsername: {
        type: String,
        required: false,
        default: null,
      },
    },
    data() {
      return {
        games: this.getPageInitialData('games', []) as any[],
        lastWagers: [],
        gameCategories: this.getPageInitialData('gameCategories', []) as any[],
        lastPrizesDistributed: [],
        stats: [
          {
            type: 'users',
            icon: 'home/icon-players.svg',
            num: '40.000',
            description: this.$t('35')
          },
          {
            type: 'prizes',
            icon: 'home/icon-prizes.svg',
            num: '$40.000',
            description: this.$t('36')
          },
          {
            type: 'matches',
            icon: 'home/icon-matches.svg',
            num: '1.000',
            description: this.$t('37')
          }
        ],
        offerBanner: true,
      }
    },
    mounted(): void {
      this.getHomePage();
      if (!isPrerender) {
        this.removePageInitialData();

        if (this.allowDeposit && this.canShowModal(MODAL_IDS.DEPOSIT_REMINDER)) {
          this.showModalByIdWithDelay(MODAL_IDS.DEPOSIT_REMINDER, null, 10000);
        }
      }
    },
    computed: {
      nextUrl(): string {
        return this.$store.getters.nextUrl;
      },
      metaData(): MetaInfo {
        return {
          title: this.$t('420'),
          meta: [
            {name: 'description', content: this.$t('421')},
            {name: 'robots', content: 'index'}
          ],
          link: this.getMetaDataPathLinks(ROUTES.home.path),
        };
      },
      showGroupLogo(): boolean {
        return !HIDE_GROUP_LOGO_PAGES[this.$route.name];
      },
      lastWagersList(): any[] {
        const list = [];
        const totalPrizesDistributed = this.lastWagers.length;
        if (totalPrizesDistributed > 0) {
          const numRows = 2;
          const prizesByRow = Math.floor(this.lastWagers.length / numRows);
          let lastEnd = 0;
          for (let i = 1; i <= numRows; i++) {
            const nextEnd = lastEnd + prizesByRow;
            const end = i < numRows
              ? nextEnd
              : totalPrizesDistributed;
            list.push(this.lastWagers.slice(lastEnd, end));
            lastEnd = end;
          }
        }
        return list;
      },
    },
    methods: {
      async getHomePage() {
        this.showLoader(true);
        try {
          let {data} = await this.$http.page.getHomePage();
          const games = data.data.games || [];
          this.games = games;
          this.lastWagers = data.data.top_wagers;
          const gameCategories = data.data.game_categories || [];
          this.gameCategories = gameCategories;
          this.lastPrizesDistributed = data.data.last_prizes_distributed;

          if (isPrerender) {
            this.savePageInitialData({games, gameCategories})
          }

          if (this.nextUrl) {
            this.showModalById(MODAL_IDS.LOGIN);
          } else if (this.referUsername) {
            this.showModalById(MODAL_IDS.SIGN_UP, {referUsername: this.referUsername});
          }
        } catch (e) {
          // Show error
        } finally {
          this.showLoader(false);
        }
      },
      signUp() {
        this.goToSignup();
      },
    },
  });
